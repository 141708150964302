import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { compose } from 'recompose';
import { LockIcon } from '../../icons';
import { FiLock } from 'react-icons/fi';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const PasswordForgetPage = () => (
  <div className="card mx-4 mt-3">
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  modal: false,
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.toggle = this.toggle.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ error: null, modal: true });
      })
      .catch(error => {
        this.setState({ error });
      });
    
    event.preventDefault();
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  onConfirm = event => {
    this.setState({ ...INITIAL_STATE, modal: false });
    this.props.history.push(ROUTES.SIGN_IN);
  }

  onChange = event => {
    this.setState({ [event.target.type]: event.target.value });
  };

  render() {
    return (
      <div>
      <form className="form-signin border-bottom" onSubmit={this.onSubmit}>
        <div className="text-center mb-4">
          <div><FiLock size="2em"/></div>
          <div className="h3 mb-3 font-weight-normal">Trouble Logging In?</div>
          <p>Enter your email and we'll send you a link to reset your password.</p>
        </div>

        <div className="form-label-group">
          <input type="email" id="inputEmail" onChange={this.onChange} className="form-control" placeholder="Email address" required autoFocus />
          <label htmlFor="inputEmail">Email address</label>
        </div>

        <button className="btn btn-lg btn-secondary btn-block" type="submit">Send Link</button>
      </form>
      <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={true}>
          {/* <ModalHeader toggle={this.toggle}>Link Sent</ModalHeader> */}
          <ModalBody>
            <div className="my-3">
            {console.log(this.state.email)}
              <span className="lead">Password reset link was sent to </span><span className="lead font-weight-bold">{this.state.email}</span>
            </div>
            <div>Check your inbox!</div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="rounded-0" onClick={this.onConfirm}>OK</Button>{' '}
          </ModalFooter>
      </Modal>
      </div>
      
    );
  }
}

const PasswordForgetLink = () => (
  <p className="">
    <Link className="text-info nounderline" to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = compose(
  withRouter,
  withFirebase
)(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };