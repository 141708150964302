import { createLogger } from 'redux-logger';
import { applyMiddleware, createStore } from 'redux';
// import thunk from 'redux-thunk'; // Maybe use thunk, in addition to logger middleware
import rootReducer from '../reducers';

const LOG = false;
const IGNORED_ACTION = '@@redux';
const logger = createLogger({
    level: 'log',
    predicate: (getState, action) => !action.type.includes(IGNORED_ACTION)
});

const createStoreWithMiddleware = LOG ? 
                                  applyMiddleware(logger)(createStore) 
                                  : applyMiddleware()(createStore);

const store = createStoreWithMiddleware(rootReducer);

export default store;