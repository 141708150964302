import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  function useEmailVerification(props) {
    const [isSent, setIsSent] = useState(false);
    const [error, setError] = useState(false);

    const onSendEmailVerification = () => {
      props.firebase.doSendEmailVerification()
        .then(() => { 
          setIsSent(true)
        })
        .catch(error => {
          setError(error);
        });
    };

    return needsEmailVerification(props.authUser) ? (
      <div>
        {isSent ? (
        <div className="card-body mt-0">
          <h1 className="text-center text-light display-5 my-3 font-weight-normal bg-primary">︴Lyddy ︴</h1>
          <p className="lead">Email Verification Sent</p>
          <p>A verification message was sent to your email</p>
          <p className="font-weight-bold">{props.authUser.email}</p>
          <p>If you didn't find it, click to resend the verification.</p>
          <div className="text-center">
            <button onClick={onSendEmailVerification} disabled={isSent} className="btn btn-secondary">{isSent? 'Verification Sent!' : 'Resend Verification'}</button>
          </div>
          <small className="text-muted">*Don't forget to also check your spam or junk inbox.</small>
        </div>
        ) : (
          <div className="card-body mt-0">
           <h1 className="text-center text-light display-5 my-3 font-weight-normal bg-primary">︴Lyddy ︴</h1>
            <p className="lead">Check your inbox!</p>
            <p>Look in your email inbox (<i>{props.authUser.email}</i>) for a verification message from Lyddy.</p>
            <div className="text-center">
              <button onClick={onSendEmailVerification} disabled={isSent} className="btn btn-secondary">{isSent? 'Verification Sent!' : 'Resend Verification'}</button>
            </div>
            <small className="text-muted">*Don't forget to also check your spam or junk inbox.</small>
          </div>
        )}
        {error && <div className="text-center text-danger">{error.message}</div>}
      </div>
    ) : (
      <Component {...props} />
    );
  }

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
  });

  return compose(
    withFirebase,
    connect(mapStateToProps),
  )(useEmailVerification);
};

export default withEmailVerification;