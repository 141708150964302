import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const withAuthUser = Component => {
  function WithAuthUser(props) {
    return <Component {...props} />;
  }

  return compose(
    connect(state => ({ 
      authUser: state.sessionState.authUser,
      isAuthenticating: state.sessionState.isAuthenticating
    })),
  )(WithAuthUser);
};

export default withAuthUser;