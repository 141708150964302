import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import { LydPlayerBar } from '../LydPlayer';
import LandingPage from '../Landing';
import ProfilePage from '../Profile';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import { 
  AboutUs,
  TermsOfUse,
  GuidelinePolicies,
  Libraries,
  ReleaseNotes,
  Support } from '../About';
import ExploreLyds from '../ExploreLyds';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import FooterMenu from '../Footer';

const AboutLinks = props => {
  return (
    <div>
      <ul className="list-inline small text-muted d-flex flex-wrap justify-content-center">
        <li className="list-inline-item"><Link className="text-muted" to={ROUTES.LANDING}>Home</Link></li>
        <li className="list-inline-item">•</li>
        <li className="list-inline-item"><Link className="text-muted" to={ROUTES.ABOUT_US}>About Us</Link></li>
        <li className="list-inline-item">•</li>
        <li className="list-inline-item"><Link className="text-muted" to={ROUTES.ABOUT_SUPPORT}>Contact</Link></li>
        <li className="list-inline-item">•</li>
        <li className="list-inline-item"><Link className="text-muted" to={ROUTES.ABOUT_TERMS}>Terms of Use</Link></li>
        <li className="list-inline-item">•</li>
        <li className="list-inline-item"><Link className="text-muted" to={ROUTES.ABOUT_GUIDELINES}>Guidelines</Link></li>
        <li className="list-inline-item">•</li>
        <li className="list-inline-item"><Link className="text-muted" to={ROUTES.ABOUT_LIBRARIES}>Libraries</Link></li>
      </ul>
      <div className="text-muted small d-flex justify-content-center">© 2019 Lyddy</div>
    </div>
  );
}

const App = () => (
  <div>
  <Router>
    <div>
      <header className="fixed-top">
        <LydPlayerBar />
      </header>
      <main role="main" className="py-main">
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route path={ROUTES.PROFILE} component={ProfilePage} />
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.EXPLORE_HASHTAGS} component={ExploreLyds} />
      <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      <Route path={ROUTES.ABOUT_US} component={AboutUs} />
      <Route path={ROUTES.ABOUT_SUPPORT} component={Support} />
      <Route path={ROUTES.ABOUT_TERMS} component={TermsOfUse} />
      <Route path={ROUTES.ABOUT_GUIDELINES} component={GuidelinePolicies} />
      <Route path={ROUTES.ABOUT_LIBRARIES} component={Libraries} />
      <Route path={ROUTES.ABOUT_RELEASES} component={ReleaseNotes} />
      <hr/>
      <AboutLinks />
      </main>
      <footer className="fixed-bottom">
        <FooterMenu />
      </footer>
    </div>
  </Router>
  </div>
);

export default withAuthentication(App);
