import React, { Component, useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Link, withRouter } from 'react-router-dom';
import Slider from 'react-rangeslider';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Duration from './Duration';
import { PlayPauseButton } from './LydPlayerControls';
import { Collapse, Tooltip, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
// import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { FiChevronsUp, 
         FiChevronsDown, 
         FiSkipBack,
         FiSkipForward,
         FiSettings,
         FiLink,
         FiSliders,
         FiTrash2,
         FiMoreVertical } from 'react-icons/fi';
import { IoIosOptions } from 'react-icons/io';
import { IoIosInformationCircleOutline, IoIosInformationCircle, IoIosSkipForward, IoIosSkipBackward, IoMdHome } from 'react-icons/io';
import { AudioBarsIcon, PlayPauseIcon } from '../icons';
import LinkedCaption from '../components/Lyds/LinkedCaption';
import { writtenDate } from '../utils';
import { withFirebase } from '../components/Firebase';
import  * as ROUTES from '../constants/routes';

import _ from 'lodash';

const LydItemActions = props => {
  // console.log("LydItemActions... ", props)
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleShow = () => setShow(!show);

  const toggleReverse = event => {
    props.onToggleReversed();
    event.stopPropagation();
  }

  const copyToClipboard = content => event => {
    navigator.clipboard.writeText(content)
    .then(() => {
      console.log('Text copied to clipboard', content);
    })
    .catch(err => {
      // This can happen if the user denies clipboard permissions:
      console.warning('Could not copy text: ', err);
    });
    event.stopPropagation();
  }

  const editItems = (
    <div className={props.className}>
      <DropdownItem header>Stream Options</DropdownItem>
      <DropdownItem onClick={toggleReverse} disabled={props.isPlaying}>
        {props.isReversed? 
        <FiChevronsDown className="mr-1 align-text-bottom" size="1.2em"/> : <FiChevronsUp className="mr-1 align-text-bottom" size="1.2em"/>
        }
        {props.isReversed? <span> Downstream Play</span> : <span> Upstream Play</span>}
      </DropdownItem>
      <DropdownItem onClick={copyToClipboard(props.lyd.source)}>
        <FiLink className="mr-1 align-text-bottom" size="1.1em"/> Copy URL
      </DropdownItem>
      {(props.location.pathname !== ROUTES.ACCOUNT) &&
      <div>
        <DropdownItem divider />
        <DropdownItem onClick={() => props.history.push(ROUTES.ACCOUNT)}>
          <FiSettings className="mr-1 align-text-bottom" size="1.1em"/> Settings
        </DropdownItem>
      </div>
      }
    </div>
  );

  return (
    <Dropdown 
      direction="left"
      group isOpen={show} 
      size="sm" 
      toggle={toggleShow}>
      <DropdownToggle
        tag="span"
        onClick={null}
        data-toggle="dropdown"
        aria-expanded={show}
      >
        <FiSliders size="1.7em"/>
      </DropdownToggle>
      <DropdownMenu>
        {editItems}
      </DropdownMenu>
    </Dropdown>
  );
}

export const SkipButton = ({amount, playing, onSkip}) => {
  const label = amount < 0? "Back" : "Next"
  return <button disabled={!playing} onClick={onSkip}>{label}</button>

}

const HashTagsList = ({ hashtags }) => {
  return _.map(hashtags, (hashtag, key) => {
    return (
      <span key={key}>#{hashtag} </span>
    );
  });
}

export const ArtistNames = ({ style, artists, delimiter }) => {
  // var artistNames = artists? artists.join(delimiter) : 'unknown';
  return artists? <p style={style}>{artists.join(delimiter)}</p> : null;
}

class VolumeSlider extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      volume: 0
    }
  }
 
  handleOnChange = (value) => {
    this.setState({
      volume: value
    })
  }
 
  render() {
    let { volume } = this.state
    return (
      <Slider
        value={volume}
        onChange={this.handleOnChange}
      />
    )
  }
}
function AddButton(props) {
  return (
    <div 
      style={{ width: "70px", height: "70px", padding: "15px 10px", fontSize: "3em", borderRadius: "50%"}}
      className="shadow p-3 lead text-dark btn-primary"
      onClick={props.onClick}
    >
    ＋
    </div>  
  );
}

function MainPlayer(props) {
  const { 
    stream,
    posts,
    onSetLyd,
    currentLyd,
    isPlaying,
    onSetPlaying,
    isReversed,
    onToggleReversed } = props;
  
  // TODO: Will endIdx change if queue changes? I think, no. Test it.
  const START_IDX = 0;
  const [endIdx, setEndIdx] = useState(posts.length); 
  const ref = useRef(null);
  const [error, setError] = useState(null);
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [duration, setDuration] = useState(0);
  const [playedToggle, setPlayedToggle] = useState(0);
  const [queueIdx, setQueueIdx] = useState(START_IDX);
  const [collapsed, setCollapsed] = useState(true);
  const [keyPressed, setKeyPressed] = useState(false);
  // console.log("MAINPLAYER props...", "currentLyd=", currentLyd, "queueIdx=", queueIdx)

  useEffect(() => {
    const currentLydIdx = posts
      .map(item => item.lydId)
      .indexOf(currentLyd.lydId);
    // console.log("^^^^^ useEffect 1.... MainPlayer props...", "queueIdx=", queueIdx,  "currentLydIdx=", currentLydIdx, " isReversed=", isReversed, "currentLyd=", currentLyd, "posts=", posts)
    setQueueIdx(currentLydIdx);
    setEndIdx(posts.length);
  }, [posts, currentLyd])

  // useEffect(() => {
  //   console.log("useEffect keypress...")
  //   // window.addEventListener('keydown', downHandler);
  //   // window.addEventListener('keyup', upHandler);
  //   window.addEventListener('keypress', pressHandler);
  //   // Remove event listeners on cleanup
  //   return () => {
  //     // window.removeEventListener('keydown', downHandler);
  //     // window.removeEventListener('keyup', upHandler);
  //     window.removeEventListener('keypress', pressHandler);
  //   };
  // }, []); // Empty array ensures that effect is only run on mount and unmount

  // const pressHandler = (event) => {
  //   event.preventDefault()
  //   console.log(event)
  //   const { key } = event;
  //   if (key === 'ArrowRight') {
  //     onNext(event);
  //   } else if (key === 'ArrowLeft') {
  //     onBack(event);
  //   } else if (key === ' ') {
  //     onSetPlaying(!isPlaying);
  //     onTogglePlay(event);
  //   } else if (key === 'ArrowDown') {
  //     const idx = (queueIdx + 1) % endIdx;
  //     const nextLyd = posts[idx];
  //     scrollTo(nextLyd.lydId);
  //   } else if (key === 'ArrowUp') {
  //     const idx = queueIdx - 1;
  //     if (idx > START_IDX) {
  //       const prevLyd = posts[idx];
  //       scrollTo(prevLyd.lydId);
  //     }
  //   }
  // };

  const onBack = () => {
    let idx = (queueIdx - 1);
    if (idx < 0) {
      idx = endIdx - 1;
    }
    const prevLyd = posts[idx];
    onSetLyd(prevLyd);
    scrollTo(prevLyd.lydId);
  }
  
  const onNext = () => {
    const idx = (queueIdx + 1) % endIdx;
    const nextLyd = posts[idx];
    onSetLyd(nextLyd);
    scrollTo(nextLyd.lydId);
  }

  const onEnded = () => {
    if (isReversed) {
      onBack();
    } else {
      onNext();
    }
  }

  const onProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(state.played)
    }
  }
  const onDuration = duration => {
    setDuration(duration)
  }
  const onSeekMouseDown = event => {
    setSeeking(true)
  }
  const onSeekChange = event => {
    setPlayed(parseFloat(event.target.value))
  }
  const onSeekMouseUp = event => {
    setSeeking(false)
    ref.current.seekTo(parseFloat(event.target.value))
  }
  const onTogglePlay = event => {
    onSetLyd(currentLyd);
    onSetPlaying(!isPlaying);
  }
  
  const onCollapse = e => {
    setCollapsed(!collapsed);
  }

  const toggleDuration = event => {
    setPlayedToggle(playedToggle? 0 : 1)
  }
  const onPlayerError = event => {
    setError({message: 'audio player error', error: event})
    console.warn('There was an error in ReactPlayer!', event)
  }

  const backPlayForwardButtons = (
    <div className="d-flex">
      <div className="border-0 bg-white align-self-center ml-2 mt-1" style={{cursor: "pointer"}} onClick={isReversed? onNext : onBack}>
        {isReversed? <FiChevronsDown size="1.8em" /> : <FiChevronsUp size="1.8em"/>}
      </div>

      {isPlaying && !(played > 0)? 
      <div style={{width: "1.5rem", height: "1.5rem"}} className="spinner-border text-dark ml-2 mr-1 mt-1" onClick={onTogglePlay} role="status"></div> :
      <div className="rounded-2 border-0 bg-white mt-1" style={{cursor: "pointer"}} onClick={onTogglePlay}>
        {isPlaying? 
          <AudioBarsIcon active={isPlaying} />: 
          <PlayPauseIcon className="mr-n1 bg-white ml-2 mr-4" size="2em" playing={isPlaying} /> 
        }
      </div>
      }

      <div className="border-0 bg-white align-self-center ml-2 mt-1" style={{cursor: "pointer"}} onClick={isReversed? onBack : onNext}>
        {isReversed? <FiChevronsUp size="1.8em"/> : <FiChevronsDown size="1.8em" />}
      </div>
    </div>
  );
  
  const barTitle = (
  <div style={{fontSize: "1.3rem"}}
    onClick={e=>scrollTo(currentLyd.lydId)} 
    className="rounded-0 lead align-self-center ml-1 mr-2 text-primary text-weight-light text-truncate">
    {queueIdx >= 0 && stream &&
      <span className="badge badge-primary text-wrap py-auto align-middle" 
        style={{maxWidth: "10rem", fontSize: "0.6em"}}
        >
      {stream}
      </span>
    } <span className="align-middle pl-1">{currentLyd.title}</span>
  </div>
);

  const scrollTo = lydId => {
    var lydElement = document.getElementById(lydId);
    if (lydElement) {
      lydElement.scrollIntoView();
      window.scrollBy(0, -55);
    }
  }

  const renderCardBody = () => {
    return (
      <div className="card-body pb-0 mx-2 bg-white">
        {/* <h5 className="card-title lead bg-primary">{title}</h5> */}
        <div className="d-inline-flex px-2 pb-2 lead" onClick={e=>scrollTo(currentLyd.lydId)}>{currentLyd.title}</div>
         {/* ︴{currentLyd.title} ︴ */}
        <h6 className="card-subtitle mb-2">
          <ArtistNames 
            artists={currentLyd.artists} 
            delimiter={' • '} 
          />
        </h6>
        <VolumeSlider />
        {/* <Slider
          min={0}
          max={1}
          step={0.00001}
          value={played}
          tooltip={true}
          labels={{ 0: 'Low', 1: 'High'}}
          handleLabel={JSON.stringify(duration * Math.abs(playedToggle - played))}
          format={v=>duration * Math.abs(playedToggle - played)}
          onChangeStart={onSeekMouseDown}
          onChange={onSeekChange}
          onChangeComplete={onSeekMouseUp}
        /> */}
        {/* <input
          type='range' min={0} max={1} step='any'
          value={played}
          onMouseDown={onSeekMouseDown}
          onChange={onSeekChange}
          onMouseUp={onSeekMouseUp}
          />
        <button 
          style={{border: 'none'}} 
          onClick={toggleDuration}>
            <Duration 
              seconds={duration * Math.abs(playedToggle - played)}
              isRemaining={!!playedToggle} 
            />
        </button> */}
        <div className="card-text mx-5 text-justify">
          <LinkedCaption hashtags={currentLyd.hashtags || []} linkClassName="nounderline text-info">
            {currentLyd.caption || ''}
          </LinkedCaption>
        </div>
        <ReactPlayer
          ref={ref}           
          width={0}
          height={0}
          controls={false}
          url={currentLyd.source}
          playing={isPlaying}
          onEnded={onEnded}
          onProgress={onProgress}
          onSeek={e => console.log('onSeek', e)}
          onDuration={onDuration}
          onError={onPlayerError}
          /> 
          <p className="mx-0 text-justify text-muted">
            <small>{writtenDate(currentLyd.dateAdded)} by <Link className="text-info" to={`/${currentLyd.user.alias_name || currentLyd.user.username}`}>@{currentLyd.user.alias_name || currentLyd.user.username}</Link></small>
          </p>
      </div>
    );
  }
  
  return (
    <div>
      <ReactPlayer
        ref={ref}           
        width={true?  0 : "100%"}
        height={true? 0 : null}
        controls={false}
        url={currentLyd.source}
        playing={isPlaying}
        onEnded={onEnded}
        onProgress={onProgress}
        onSeek={e => console.log('onSeek', e)}
        onDuration={onDuration}
        onError={onPlayerError}
      /> 
      <div style={{maxHeight: "55px", borderWidth: "1px", borderStyle: "solid hidden solid hidden"}} className="d-flex px-2 py-2 bg-white">
        {/* <div className="align-self-center">
          {isPlaying && !(played > 0)? 
            <div className="spinner-border text-dark mt-1 ml-1 mr-1" onClick={onTogglePlay} role="status"></div> :
            <button className="rounded-2 border-0 bg-white" onClick={onTogglePlay}>
              {isPlaying || collapsed? 
                <AudioBarsIcon active={isPlaying} /> : 
                <PlayPauseIcon className="mr-n1 bg-white" size="2em" playing={isPlaying} /> 
              }
            </button>
          }
        </div> */}
        {collapsed ? 
        (
        <div className="d-flex flex-row justify-content-between text-truncate"> 
          <div className="align-self-center">{backPlayForwardButtons}</div> 
          {barTitle}
        </div>
        )
        :
        barTitle
        }
        {/* <div className="btn my-1 rounded-0 align-self-center ml-auto" onClick={onCollapse}>
          {collapsed? <IoIosInformationCircleOutline size="1.7em"/> : <IoIosInformationCircle className="text-primary" size="1.7em"/>}
        </div> */}
        <div className="btn my-1 rounded-0 align-self-center ml-auto" style={{cursor: "pointer"}}>
          <LydActions lyd={currentLyd}  />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  posts: state.lydsState.posts,
  currentLyd: state.lydsState.lyd || state.lydsState.posts[0] || {},
  isPlaying: state.lydsState.playing,
  isReversed: state.lydsState.reversed
});

const mapDispatchToProps = dispatch => ({
  onSetLyd: lyd => dispatch({type: 'LYD_SET', lyd}),
  onToggleReversed: () => dispatch({type: 'TOGGLE_REVERSED'}),
  onSetPlaying: playing => dispatch({type: 'PLAYING_SET', playing}),
});

const LydActions = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withFirebase
)(LydItemActions);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )
)(MainPlayer);