import React, { useState } from 'react';
import { EyeToggleIcon } from '../../icons';
import { withFirebase } from '../Firebase';

function PasswordChangeForm(props) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [completed, setCompleted] = useState(false);

  const onSubmit = event => {
    props.firebase
      .doPasswordUpdate(password)
      .then(() => {
        setPassword('')
        setError(null);
        setCompleted(true);
      })
      .catch(error => {
        console.log(error)
        setError(error);
      });

    event.preventDefault();
  };

  const onChange = event => {
    setPassword(event.target.value);
    setCompleted(false);
  };

  const parsedValidation = () => {
    if (password) {
      if (error) {
        return 'is-invalid';
      }
    } else {
      if (completed) {
        return 'is-valid';
      }
    }
    return '';
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="d-inline-flex bd-highlight">
        <div className="form-label-group  bd-highlight my-0 ">
          <input
            name="password"
            id="inputPassword"
            value={password}
            onChange={onChange}
            className={`form-control ${parsedValidation()}`} 
            type={show? "text" : "password"}
            placeholder="New Password"
            required
          />
          <label htmlFor="inputPassword">New Password</label>
          <div className={completed? 'valid-feedback' : 'invalid-feedback'} >
            <span>{completed? 'Password successfully changed' : ''}{error && error.message}</span>
          </div>
        </div>

        <div className="bd-highlight ml-2 mt-2">
          <button onClick={e => setShow(!show)} type="button" className="bg-white border-0">
            <EyeToggleIcon size="1.2em" show={show} />
          </button>
        </div>

        <div className="bd-highlight mt-2">
          <button 
            className="btn btn-sm btn-secondary mx-2"
            type="submit"
            disabled={!password.length}
          >
            Change
          </button>
        </div>
      </div>
    </form>
  )
}

export default withFirebase(PasswordChangeForm);