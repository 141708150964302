import React, { useState, useEffect } from 'react';
import { Link, Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { FiSettings } from 'react-icons/fi';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { LydsFeed } from '../Lyds';
import AccountPage from '../Account';
import withLoadingSpinner from '../Loading';
import { SocialButton } from '../Social';
import { usePrevious } from '../../hooks';
import * as SOCIAL from '../../constants/social';
import { MutualFollowersList, SocialConnectionsModal } from '../Social';
import ProfilesList from './ProfilesList';
import { FullnameField, BiographyField, WebsiteField } from '../Account/EditProfile';
import UsernameChangeForm from '../UsernameChange';
export { ProfilesList };
export default function ProfileAccountPage(props) {
  // console.log('ProfilePage props:', props)
  return (
    <Switch>
      <Route exact path={ROUTES.PROFILE} component={ProfilePage} />
      <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
    </Switch>
  );
}

const ProfilePhoto = props => {
  const imgDivStyle = {
    width: '150px',
    height: '150px',
    borderColor: 'lightgray',
    borderStyle: 'solid',
    borderWidth: '1px'
  }
  const imgStyle = {
    display: 'inline',
    margin: '0 auto',
    height: '100%',
    width: 'auto',
  }
  
  if (false && props.isAuthUser) {
    return (
      <div>
        <button title="Change Profile Photo"><img src={props.src} alt="Change Profile Photo" width="72" height="72"/></button>
        <div>
          <form encType="multipart/form-data"><input accept="image/jpeg,image/png" type="file"/></form>
        </div>
      </div>
    )
  } else {
    return (
      <div className={props.className}>
        <img className="rounded-circle" style={imgDivStyle} 
          src={props.src} alt={props.username} />
      </div>
    )
  }
}

const ProfileHeader = withLoadingSpinner(props => {
  // console.log("ProfileHeader props:", props)
  const {
    editMode,
    isAuthProfile,
    username,
    photoURL,
    fullname,
    bio,
    postsTotal,
    followersTotal,
    followingTotal, 
    mutualFollowers,
    website
  } = props

  const parsedWebsite = website? 'http://' + website.replace('http://', '') : '';
  return (

    <div className="container mt-3"> 
    <div className="row no-gutters">
      <div className="col-md-3">
        <ProfilePhoto  className="text-center" src={photoURL} username={username} />
      </div>
      <div className="col-md-9">
        <div className="card-body">
          <div className="d-flex bd-highlight mb-3">
            <div className={`bd-highlight align-self-center ${editMode? '' : 'h3 font-weight-light'} pr-2`}>
              {editMode? <div className="pt-3"><UsernameChangeForm skipConfirm placeholder={username}/></div> : username}
            </div>
            <div className="bd-highlight align-self-center col-4">
              {props.socialButton}
            </div>
            {/* <div className="ml-auto p-2 bd-highlight">Flex item</div> */}
          </div>
          <div className="card-text">
          <div className="d-flex bd-highlight">
            <div className="bd-highlight mr-3"><strong>{postsTotal}</strong><span> post{postsTotal === 1? '' : 's'}</span></div>
            <div className="bd-highlight mx-3" style={followersTotal? {cursor: "pointer"} : null } onClick={followersTotal? props.onClickSocialNet('followers') : null}>
                <strong>{followersTotal}</strong><span> follower{followersTotal === 1? '' : 's'}</span>
            </div>
            <div className="bd-highlight mx-3" style={followingTotal? {cursor: "pointer"} : null } onClick={followingTotal? props.onClickSocialNet('following') : null}>
              <strong>{followingTotal}</strong><span> following</span>
            </div>
          </div>
            <div className={`pt-3 ${editMode? "" : "font-weight-bold"}`}>{!editMode? fullname : <FullnameField placeholder={fullname} skipConfirm />}</div>
            <div>{!editMode? bio : <BiographyField skipConfirm inputType="area" placeholder={bio} />}</div>
             {website && 
             <div>
              {!editMode? 
                <a className="text-primary text-decoration-none" href={parsedWebsite} target='_blank'>{website.replace('http://', '')}</a>
                : <WebsiteField placeholder={"URL"} skipConfirm />}
              </div>
             }
            {!isAuthProfile && <div className="d-inline-flex mt-3" style={mutualFollowers? {cursor: "pointer"} : null } onClick={props.onClickSocialNet('followers')}>
                <MutualFollowersList socialItems={mutualFollowers}/>
              </div>}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
});

function ProfileError({ message }) {
  return (
    <div className="card text-center mx-auto w-75">
      <div className="card-body">
        <h4 className="card-title">Sorry, this page isn't available.</h4>
        <p className="card-text">
          {message[0].toUpperCase() + message.slice(1)} or the page may have been removed. 
          <a href='/'> Go back to homepage</a>.
        </p>
      </div>
    </div>
  )
}

function Profile(props) {
  // console.log("^^^^^^^^^^^^ Profile  props", props)
  const username = props.match.params.username;
  const [profile, setProfile] = useState({});
  const [postsTotal, setPostsTotal] = useState(0);
  const [followingTotal, setFollowingTotal] = useState(0);
  const [followersTotal, setFollowersTotal] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAuthProfile, setIsAuthProfile] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [mutualFollowers, setMutualFollowers] = useState([]);
  const [queriedNetwork, setQueriedNetwork] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const prevLocation = usePrevious(props.location.key);
  const [currentUid, setCurrentUid] = useState(null);
  
  useEffect(() => {
    setLoading(true);
    props.firebase.getUidFromUsername(username).then(uid => {
      if (uid) {
        setCurrentUid(uid);
        setLoading(false);
      }
    })
    .catch(setError);
  }, [props.match.params])

  useEffect(() => {
    if (!currentUid) return;
    setIsAuthProfile(props.authUser && (props.authUser.uid === currentUid));
    props.firebase.db.ref(`users/${currentUid}`).on('value', onFetchProfile);
    props.firebase.db.ref(`posts/${currentUid}`).on('value', ss => setPostsTotal(ss.numChildren()) );
    props.firebase.db.ref(`user_network/${currentUid}/following`).on('value', ss => setFollowingTotal(ss.numChildren()) );
    props.firebase.db.ref(`user_network/${currentUid}/followers`).on('value', ss => setFollowersTotal(ss.numChildren()) );
    if (props.authUser && (props.authUser.uid !== currentUid)) {
      props.firebase
        .getMutualConnectionProfiles(currentUid, 'followers', true)
        .then(setMutualFollowers);
    }

    return () => {
      props.firebase.db.ref(`users/${currentUid}`).off('value', onFetchProfile);
      props.firebase.db.ref(`posts/${currentUid}`).off('value', ss => setPostsTotal(ss.numChildren()));
      props.firebase.db.ref(`user_network/${currentUid}/following`).off('value');
      props.firebase.db.ref(`user_network/${currentUid}/followers`).off('value');
    }
  }, [currentUid]);
  
  const onFetchProfile = async snapshot => {
    const userProfile = snapshot.val();
    let connStatus = { status: SOCIAL.UNFOLLOW_CODE };
    if (props.authUser) {
      connStatus = await props.firebase
        .getConnectionStatus(props.authUser.uid, 'following', userProfile.userId);
      userProfile['status'] = connStatus.status;
    }

    setIsFollowing(connStatus.status === SOCIAL.FOLLOW_CODE);
    setProfile(userProfile);
  }

  useEffect(() => {
    setShowModal(false)
    setError(null);
  }, [prevLocation])

  const updateSocialStatus = socialItem => {
    setProfile({ ...profile, status: socialItem.status })
  }

  const onEditProfile = event => {
    if (!props.authUser.emailVerified) {
      props.history.push('/');
      return;
    }
    setEditMode(!editMode);
  }

  const unavailable = error && (error.code === 'USER_PROFILE_ERROR');
  if (unavailable) {
    document.title = 'Lyddy | Page Not Found';
    return <ProfileError message={error.message} />;
  }

  const isLoading = () => (props.isAuthenticating || loading);

  let socialButton = null;
  if (!props.authUser) {
    socialButton = <button onClick={e => props.history.push(ROUTES.SIGN_IN)} className="btn btn-primary">Follow</button>;
  } else if (isAuthProfile) {
    // socialButton = (
    //   <div className="d-flex bg-highlight">
    //     <div className="bd-highlight">
    //       <button className="btn btn-primary">Edit Profile</button>
    //     </div>
    //     <div className="bd-highlight align-self-center ml-2 bg-secondary">
    //       <Link to={ROUTES.ACCOUNT}>
    //       <FiSettings className="text-dark" size="1.6em"/></Link>
    //     </div>
    //   </div>
    // );
    socialButton = (
      <div className="d-flex bg-highlight mx-n4">
          <button onClick={onEditProfile} className={`btn btn-${editMode? 'outline-primary' : 'primary' } btn-sm btn-block ml-3`}>{editMode? 'Finish Editing' : 'Edit Profile'}</button>
          <Link className="ml-3 align-self-center" to={ROUTES.ACCOUNT}><FiSettings className="text-dark" size="1.6em"/></Link>
      </div>
    );
  } else {
    socialButton = (
      <SocialButton className="btn-sm" uid={currentUid} onFollowCb={e => updateSocialStatus} />
    )
  }

  const renderFeed = () => {
    // console.log(profile, error)
    if (isLoading()) {
      return null;
    }
    
    if (isAuthProfile || isFollowing || profile.public) {
      return <LydsFeed />;
    } else {
      if (props.authUser && profile.username) {
        return <ProfilePrivateFollowNote username={profile.username} connectionStatus={profile.status} />;
      } else {
        return <ProfilePrivateUnauthNote username={profile.username} />;
      }
    }
  }

  const onClickSocialNet = network => event => {
    if (props.authUser) {
      setQueriedNetwork(network);
      setShowModal(true);
    } else {
      setShowModal(false);
      props.history.push(ROUTES.SIGN_IN);
    }
  }

  // const onUpdated = (prop, propVal) => event => {
  //   setProfile({ ...profile, [prop]: propVal });
  //   setTimeout(() => {
  //     setEditMode(false);
  //     props.history.push(`/${propVal}`);
  //   }, 3000);
  // }

  const profileStats = { postsTotal, followersTotal, followingTotal };
  return (
    <div>
      <ProfileHeader 
        editMode={editMode}
        loading={isLoading()}
        loadingMessage={`Loading ${username}...`}
        socialButton={socialButton}
        mutualFollowers={mutualFollowers}
        isAuthProfile={isAuthProfile}
        onClickSocialNet={onClickSocialNet}
        // onUpdated={onUpdated}
        {...profile}
        {...profileStats}
      />
    <hr/>
    {props.authUser && <SocialConnectionsModal  network={queriedNetwork} onShow={e => setShowModal(!showModal)} authUserId={props.authUser.uid} userId={currentUid} show={showModal}/>}
    {renderFeed()}
    </div>
  );
}  

const ProfilePrivateUnauthNote = ({ username }) => (
  <div className="card text-center mx-auto w-75">
  <div className="card-body">
    <p className="card-text">Already follow  <b>{username}</b>?</p>
    <p className="card-text">
      <Link to={ROUTES.LANDING}>Log in</Link> to listen to their sound stream.
    </p>
  </div>
</div>

  // <div>
  //   Already follow  <b>{username}</b>? 
  //   <br></br>
  //   <Link to={ROUTES.LANDING}>Log in </Link> 
  //   to see their posts.
  // </div>
);

const ProfilePrivateFollowNote = ({ username, connectionStatus }) => {  
  let message = "Follow to listen to their sound stream.";
  if (connectionStatus === SOCIAL.FOLLOW_REQUEST_CODE) {
    message = "Follow Request is pending."
  }
  return (
    <div className="card text-center mx-auto w-75">
      <div className="card-body">
        <h6 className="card-title">{username}{username.endsWith('s')? "'" : "'s"} Account is Private</h6>
        <p className="card-text">{message}</p>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  isAuthenticating: state.sessionState.isAuthenticating
});

const ProfilePage = compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps)
  )(Profile);