import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import UsernameChangeForm from '../UsernameChange';
import { AccountStatusModal } from '../SignIn';

const SignUpPage = () => (
  <div className="card mx-4 mt-3">
    <SignUpForm />
    <div className="text-center">
      <p className="text-center my-4">
        Have an account? <Link className="text-info nounderline" to={ROUTES.SIGN_IN}>Log in</Link>
      </p>
    </div>
  </div>
);

const INITIAL_FIELDS_STATE = {
  email: '',
  fullname: '',
  username: '',
  password: ''
};

// SignUpForm hook (i.e. function component)
function useSignUpForm(props) {
  const [fields, setFields] = useState({ ...INITIAL_FIELDS_STATE });
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const {
    email,
    fullname,
    username,
    password,
  } = fields;

  const onSubmit = event => {
    const { firebase, history } = props
    
    event.preventDefault();
    setShowModal(true);
    firebase
      .createNewUserAccount(email, password, username, fullname)
      .then(profile => {
        setFields({ ...INITIAL_FIELDS_STATE });
        firebase.doSignOut();
        // history.push(ROUTES.LANDING);
      })
      .catch(err => {
        console.log(err)
        setError({ message: err.message });
      });
  }

  const onChange = event => {
    const newState = {
      ...fields, 
      [event.target.name]: event.target.value 
    };
    setFields(newState);
  };

  return (
    <div>
      <form className="form-signin" onSubmit={onSubmit}>
        <div className="text-center mb-4">
          <h1 className="display-5 my-3 font-weight-normal bg-primary text-light">︴Lyddy ︴</h1>
          <h1 className="lead px-3">Sign up to share sounds and music with your friends.</h1>
        </div>

        <div className="form-label-group">
          <input 
            name="email"
            type="email" 
            id="inputEmail" 
            onChange={onChange} 
            className="form-control" 
            placeholder="Email address" 
            required autoFocus />
          <label htmlFor="inputEmail">Email address</label>
        </div>
        <div className="form-label-group">
          <input
            name="fullname"
            id="fullName" 
            value={fullname}
            onChange={onChange}
            className="form-control"
            type="text"
            placeholder="Full Name"
          />
          <label htmlFor="fullName">Full Name</label>
        </div>
        <UsernameChangeForm
          hasParent={true}
          inputOnly={true}
          skipConfirm={true}
          className="form-label-group" 
          onChange={onChange}
          placeholder={'Username'}
          fieldName="username"
        />
        <div className="form-label-group">
          <input 
            name="password"
            type="password" 
            id="inputPassword" 
            onChange={onChange} className="form-control" 
            placeholder="Password" 
            required />
          <label htmlFor="inputPassword">Password</label>
        </div>
        <button className="btn btn-lg btn-secondary btn-block" 
          type="submit">Sign up</button>
      </form>
      {error && <div className="text-center text-danger my-4">{error.message}</div>}
      <AccountStatusModal show={showModal} onShow={() => setShowModal(!showModal)}>
        <div className="card-body mt-0">
        <h1 className="text-center text-light display-5 my-3 font-weight-normal bg-primary">︴Lyddy ︴</h1>
        <p className="lead">Thanks for signing up!</p>
        <p>We're in the process of activating other accounts. Once your account has been activated, you'll receive a verification email.</p>
        <p>In the meantime, check your inbox.</p>
        <small className="text-muted">*Don't forget to also check your spam or junk inbox.</small>
        </div>
      </AccountStatusModal>
    </div>
  );
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link className="text-info nounderline" to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(useSignUpForm);

export default SignUpPage;

export { SignUpForm, SignUpLink };