import * as SOCIAL from '../constants/social';
import _ from 'lodash'

export function updateAuthFollowing(userId, socialItem) {
  return {
      type: 'UPDATE_USER_FOLLOWING', 
      userId,
      socialItem
  } 
}
export function receiveAuthFollowing(following) {
  return {
      type: 'RECEIVE_USER_FOLLOWING', 
      following
  } 
}

export function requestSocialNetwork(requested) {
  return {
    type: SOCIAL.REQUEST_SOCIALNETWORK,
    requested
  }
}

export function handleFetchError(stream, error) {
  return {
    type: SOCIAL.HANDLE_SOCIAL_ERROR,
    receivedAt: Date.now(),
    stream,
    error
  }
}

export function receiveSocialNetwork(userId, net, items) {
  return {
    type: SOCIAL.RECEIVE_SOCIALNETWORK,
    userId,
    net,
    items,
    receivedAt: Date.now(),
  }
}
export function updateSocialNetworkItem(userId, item) {
  return {
    type: SOCIAL.UPDATE_SOCIALNETWORK_ITEM,
    receivedAt: Date.now(),
    userId,
    item
  }
}

export const getFollowStatusName = followStatus => {
  switch (followStatus) {
    case SOCIAL.UNFOLLOW_CODE:
      return "follow"
    case SOCIAL.FOLLOW_REQUEST_CODE:
      return "requested"
    case SOCIAL.FOLLOW_CODE:
      return "following"
    case SOCIAL.BLOCK_CODE:
      return "blocked"
    default:
      return "follow"
  }
}

export const getReverseFollowVerb = followStatus => {
  switch (followStatus) {
    case SOCIAL.UNFOLLOW_CODE:
      return "follow"
    case SOCIAL.FOLLOW_REQUEST_CODE:
      return "remove follow request"
    case SOCIAL.FOLLOW_CODE:
      return "unfollow"
    case SOCIAL.BLOCK_CODE:
      return "unblock"
    default:
      return ""
  }
}
