import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ProfilesList from '../Profile/ProfilesList'; 
import { withFirebase } from '../Firebase';
import { FiX } from 'react-icons/fi';

export function SuggestionsModal(props) {
  return (
    <div>
      <Modal isOpen={props.show} toggle={props.onShow} className="modal-dialog modal-lg">
        {props.headerTitle &&
        <ModalHeader toggle={props.onShow} className="text-center">
          <span className="text-capitalize">
            {props.headerTitle}
          </span>
        </ModalHeader>}
        <ModalBody className="mt-n3">
          {props.children}
        </ModalBody>
      </Modal>
    </div>
  );
}


function SuggestedPeople(props) {
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [show, setShow] = useState(true);
  const authUid = props.firebase.auth.currentUser.uid;


  useEffect(() => {
    fetchProfiles();
  }, []);

  async function fetchProfiles() {
    const allProfiles = await props.firebase.getActivatedProfiles();
    const username = props.firebase.auth.currentUser.displayName;
    const followingProfiles = await props.firebase.getFollowingProfiles(username);
    const suggestedProfiles = [];
    for (var uid in allProfiles) {
      if (!followingProfiles[uid] && (authUid !== uid)) {
        const mutualFollowers = await props.firebase
                                .getMutualConnectionProfiles(uid, 'followers', true);
        suggestedProfiles.push( {...allProfiles[uid], ...followingProfiles[uid], mutualFollowers });
      }
    }
    setProfiles(suggestedProfiles);
    setLoading(false);
  }

  function onClose(event) {
    if (props.onClose) props.onClose(event);
    setProfiles([]);
    setShow(false);
  }

  function onFollowCb(event) {
    return (followItem, uid) => {
      if (followItem.status === 2) {
        const updatedProfiles = profiles.filter(profile => profile.userId !== uid);
        setProfiles(updatedProfiles);
      } 
    }
  }

  if (!profiles.length) return null;

  if (props.modal) {
    return (
      <div>
        <Modal centered isOpen={!loading && show} toggle={e => setShow(!show)} className="modal-dialog modal-lg">
          <ModalHeader toggle={e => setShow(!show)} className="modal-title text-center">
              People to Follow
          </ModalHeader>
          <ModalBody className="mt-n3 bg-white">
            <ProfilesList 
              profiles={profiles} 
              authUid={authUid} 
              isPending={false}
              onFollowCb={onFollowCb}
            />
          </ModalBody>
        </Modal>
        
      </div>
    );
  } else {
    if (!show) return null;
    return (
      <div className="card border-primary mt-2 rounded-1">
        <div className="card-header text-center h5 text-primary">
        <div className="d-flex flex-row align-items-center bd-highlight">
          <div className="mx-auto w-100 bd-highlight">
            People To Follow
          </div>
          <div className="bd-highlight ml-auto">
            <FiX onClick={onClose} style={{cursor: "pointer"}} className="text-primary" size="1.4em" />
          </div>
        </div>
        </div>
        <div className="card-body bg-light">
          <ProfilesList 
            bgColor="bg-light"
            profiles={profiles} 
            authUid={authUid} 
            isPending={false}
            onFollowCb={onFollowCb} 
          />
        </div>
      </div>
    );
  }
}

export default withFirebase(SuggestedPeople);