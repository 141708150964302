import React, { useState, useEffect } from 'react';
import { FUNNY_FACES as FACES } from './spinners';

function LoadingSpinner({ children }) {
  const [spinner, setSpinner] = useState('');
  const INTERVAL = 250;
  let count = 0;

  const setFace = () => {
    const idx = count++ % 7
    setSpinner(FACES[idx]);
  }

  useEffect(() => {
    const intervalId = setInterval(setFace, INTERVAL);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="m-5">
      {children}
      <br></br>
      <h3>{spinner}</h3>
    </div>
  );
}

const withLoadingSpinner = Component => props => {
  if (props.loading) {
    return (
      <LoadingSpinner >
        {props.loadingMessage || ''}
      </LoadingSpinner>
    );
  } else {
    return <Component {...props} />;
  }
}
export { LoadingSpinner };

export default withLoadingSpinner;
