import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import { FiUserCheck } from 'react-icons/fi';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ProfilesList from '../Profile/ProfilesList';
import { SIGN_IN } from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { withAuthUser } from '../Session';

function LikesModal(props) {
  return (
    <div>
      <Modal isOpen={props.show} toggle={props.onShow} className="modal-dialog modal-lg">
        <ModalHeader toggle={props.onShow} className="text-center">
          <span className="text-capitalize text-center">
            Liked By
          </span>
        </ModalHeader>
        <ModalBody>
          {props.socialItems.length? 
          <ProfilesList 
            profiles={props.socialItems} 
            authUid={props.authUserId} 
            isPending={false}
            />
            :
            <div className="text-center">
            <FiUserCheck  size="3em" />
            <div>No Likes</div>
            </div>
          }
        </ModalBody>
      </Modal>
    </div>
  );
}

function TotalLikes(props) {
  const [numLikes, setNumlikes] = useState(0);
  const [userLikes, setUserlikes] = useState([]);
  const [userProfiles, setUserProfiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const uid = props.lyd.userId;
  const lydId = props.lyd.lydId;
  
  useEffect(() => {
    const listener = props.firebase.db.ref(`postLikes/${uid}/${lydId}`);
    listener.on('value', ss => {
      setNumlikes(ss.numChildren())
      setUserlikes(ss.val());
    });

    return () => listener.off('value', ss => setNumlikes(0));
  }, []);

  const onClickLikes = event => {
    event.preventDefault();
    if (!props.authUser) {
      return
    }

    if (!userLikes) {
      return;
    }

    props.firebase.getUserProfiles(Object.keys(userLikes))
      .then(profiles => {
        setUserProfiles(profiles);
        setShowModal(true);
      });
  }

  return (
    <div className="mt-2" onClick={onClickLikes} style={{cursor: (userLikes? "pointer" : null)}}>
      <p className="font-weight-bold" style={{fontSize: "0.8rem"}}>
        {numLikes} Like{numLikes === 1? '' : 's'}
      </p>
      <LikesModal 
        show={showModal}
        onShow={e => setShowModal(!showModal)}
        socialItems={userProfiles}
        authUserId={props.authUser && props.authUser.uid}
      />
    </div>
  );
}
const TotalUserLikes = compose(
  withAuthUser,
  withFirebase
)(TotalLikes);


function LikeButton(props) {
  const { 
    firebase,
    lyd
  } = props;
  
  const [loading, setLoading] = useState(false);
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    if (lyd.lydId) {
      setLoading(true);
      try {
        firebase.isPostLiked(lyd).then(isLiked => {
          setLiked(isLiked);
          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
      }
    }
  }, [lyd.lydId]);

  const likedCallback = snapshot => {
    setLiked(!!snapshot.val());
    setLoading(false);
  }

  const onLike = event => {
    event.preventDefault()
    setLoading(true);
    if (!firebase.auth.currentUser) {
      props.history.push(SIGN_IN);
    } else {
      firebase.setLike(lyd, likedCallback)
    }
  }

  return (
    <div 
      className={`btn btn-white ${props.className}`}
      style={{cursor: "pointer"}}  
      onClick={onLike}
      disabled={loading}
    >
      {loading? 
      <div className="spinner-border spinner-border-sm mr-1" role="status"></div> :
      (liked? 
      <FaHeart className="text-danger" size="1.5em"/> :
      <FaRegHeart className="text-dark" size="1.5em"/> 
      )
      }
    </div>
  )
}

export { TotalUserLikes };
export default compose(
  withRouter,
  withFirebase
)(LikeButton);