import { combineReducers } from 'redux';
import sessionReducer from './session';
import lydsReducer from './lyds';
import socialReducer from './social';

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  lydsState: lydsReducer,
  socialState: socialReducer,
});

export default rootReducer;