import React, { useState, useEffect, useReducer } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { IoIosNotifications, IoIosFlame } from 'react-icons/io';
import { FaHeart } from 'react-icons/fa';
import { FiUserCheck } from 'react-icons/fi';
import { compose } from 'recompose';
import { withAuthUser } from '../Session';
import { ProfilesList } from '../Profile';
import { parsedDaysAgo } from '../../utils';
import { usePrevious } from '../../hooks';
import moment from 'moment';

function reducer(state, action) {
  switch (action.type) {
    case 'SET_LIKES':
      return { ...state, likeItems: [ ...action.likeItems ] };
    default:
      throw new Error("oops. something broke!");
  }
}

function NotificationsBadge(props) {
  const [pendingFollowers, setPendingFollowers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [state, dispatch] = useReducer(reducer, {likeItems: []});
  const [numLikes, setNumLikes] = useState(0);
  const [likesProfiles, setLikesProfiles] = useState({});
  const [loading, setLoading] = useState(props.isLoading);
  const authUid = props.authUser.uid;

  useEffect(() => {
    setLoading(true);
    const listener = props.firebase
                        .getSocialNetworkListener(
                          authUid, 
                          'followers_pending',
                          followPendingCb
                        );

    const likesListener = props.firebase.db.ref(`postLikes/${authUid}`);
    likesListener.on('value', postLikesCb);
    return () => {
      listener.off('value', followPendingCb);
      likesListener.off('value', postLikesCb);
    }
  }, []);

  useEffect(() => {
    setNumLikes(state.likeItems.length);
  }, [state.likeItems]);

  const postLikesCb = async snapshot => {
    const MIN_TIME = 2592000; // Filter with 30-day buffer
    const lastViewedLikes = await props.firebase.getLastViewedLikes();
    const items = Object.values(snapshot.val() || {});
    const likeItems = [];
    const profileUids = [];
    items.forEach(item => {
      Object.values(item).forEach(i => {
        if ((lastViewedLikes - MIN_TIME) < i.dateLiked) { 
          likeItems.push(i);
          if (!profileUids.includes(i.likedBy)) profileUids.push(i.likedBy);
        }
      })
    });
    dispatch({ type: 'SET_LIKES', likeItems });
    props.firebase.getUserProfiles(profileUids).then(profiles => {
      const likesProfs = {};
      profiles.forEach(profile => likesProfs[profile.userId] = profile);
      setLikesProfiles(likesProfs);
    });
  }

  const onInspect = event => {
    event.preventDefault();
    props.firebase.setLastViewedLikes(moment().valueOf());
    setShowModal(!showModal);
  }

  
  const followPendingCb = async snapshot => {
    const pending = snapshot.val() || {};
    // const lastViewed = 1553545225176; // Keep as a test.
    // const lastViewed = await props.firebase.getLastViewedSocial();
    // console.log("lastViewed", lastViewed)
    // const pendingUids = getNewPendingUids(pending, lastViewed);
    const pendingUids = Object.keys(pending);
    // console.log("pendingUids", pendingUids)
    props.firebase.getUserProfiles(pendingUids)
      .then(pendingFollowers => {
        const mergedPending = pendingFollowers.map(
          profile => ({ ...profile, ...pending[profile.userId] })
        );
        // console.log("mergedPending", mergedPending)
        setPendingFollowers(mergedPending);
        setLoading(false);
      });
  }
  // TODO: Keep this maybe for feature modification later.
  const getNewPendingUids = (socialItems, lastViewed) => {
    const uids = [];
    for (var uid in socialItems) {
      const socialItem = socialItems[uid];
      let dateRequested = socialItem.dateRequested;
      if ((typeof dateRequested) === 'string') {
        dateRequested = moment(dateRequested).valueOf();
      }
      if (lastViewed < dateRequested) uids.push(uid);
    }
    return uids;
  }

  // TODO: Move notifications badge styling logic to SCSS?
  return (
    <div style={{cursor: "n-resize"}} onClick={onInspect} className="bd-highlight mr-1">
      <div className="d-flex flex-column bd-highlight">
        <div className="bd-highlight mt-n2">
          {!!pendingFollowers.length && 
          <div className="position-relative"> 
            <SocialNetworkNotificationBadge />
          </div>
          }
        </div>
        <div className="bd-highlight mt-auto">
          {!!numLikes && 
          <LikesNotificationBadge numLikes={numLikes} />
          }
        </div>
      </div>
      
      <NotificationsModal 
        show={showModal}
        onShow={e => setShowModal(!showModal)}
        socialItems={pendingFollowers}
        likeItems={state.likeItems}
        likesProfiles={likesProfiles}
        authUserId={authUid}
        onClearLikes={e => dispatch({ type: 'SET_LIKES', likeItems: [] })}
      />
    </div>
  );
}

function SocialNetworkNotificationBadge(props) {
  return (
    <div>
      <IoIosNotifications className="text-danger" size="1.4em"/>
    </div>
  );
}

function LikesNotificationBadge(props) {
  const [numLikes, likesStyle] = parsedLikesParams(props.numLikes);

  return (
    <div className="position-relative">
      <FaHeart size="1.35em" className="text-danger position-absolute"/>
      <div style={likesStyle} className="position-absolute text-light">{numLikes}</div>
    </div>
  );
} 

function parsedLikesParams(total) {
  if (!total) return [null, null];
  var power = Math.floor(Math.log(total) * Math.LOG10E);
  let numLikes = total.toString();
  let likesStyle = { top:"0.2em", left: (total > 1)? "0.60em" : "0.62em", fontSize:"75%" };
  if (power ===  1) {
    likesStyle = { top:"0.28em", left:"0.48em", fontSize:"65%" };
  } 
  // Wow.
  if (power === 2) {
    likesStyle = { top:"0.2em", left: (total > 199? "0.2em" : "0.3em"), fontSize:"25%" };
  }
  // Yeah, ok.
  if (power === 3) {
    const kval = Math.floor(total / 1000);
    const isExact = total === (kval * 1000);
    numLikes = `${isExact? '' : '>'}${kval}k`;
    likesStyle = { top:"0.3em", left: (isExact? "0.7em" : "0.3em"), fontSize:"50%" };
  }
  // Lit.
  if (power > 3) {
    likesStyle = { top:"0.1em", left: "0.35em", fontSize:"75%" };
    numLikes = (<IoIosFlame />);
  }

  return [numLikes, likesStyle];
}

function LikeActivityList(props) {
  const onClickUser = username => event => {
    props.onShow();
    props.history.push(`/${username}`);
    event.preventDefault();
  }

  return (
    <ul className="list-group list-group-flush borderless">
      {props.likeItems.map(likeItem => {
        const profile = props.profiles[likeItem.likedBy];
        if (profile) {
        return (
          <li key={profile.userId + likeItem.lydId} 
            className="list-group-item">
            
            <div className="d-flex bd-highlight mx-n3">
              <div className="bd-highlight align-self-center">
                <div style={{cursor: "pointer"}} onClick={onClickUser(profile.alias_name || profile.username)}>
                  <img className="rounded-circle alias-thumbnail" src={profile.alias_image || profile.photoURL} />
                </div>
              </div>
              <div className="bd-highlight align-self-center">
                <div className="d-flex flex-column bd-highlight ml-2">
                  <div className="bd-highlight">
                  <div style={{cursor: "pointer"}} onClick={onClickUser(profile.alias_name || profile.username)} className="nounderline text-dark">{props.authUser.uid === profile.userId? 'You' : (profile.alias_name || profile.username)}</div>
                  </div>
                  {false && (profile.full_name || profile.fullname) && <div className="bd-highlight mt-n2"><small className="text-muted">{profile.full_name || profile.fullname}</small></div>}
                  {false && <div className="bd-highlight mt-n2"><small className="text-muted">Followed by matthew, slothroach69...</small></div>}
                </div>
              </div>
              <div className="bd-highlight align-self-center text-truncate ml-1 mr-auto">
                liked <span className="lead" style={{fontSize: "1rem"}}> {likeItem.title}</span>
              </div>
              <small className="bd-highlight text-muted align-self-center ml-2 text-truncate">
                {parsedDaysAgo(likeItem.dateLiked)}
              </small>
            </div>

          </li>
        )}
      })
      }
    </ul>
  );
}
const LikeActivity = compose(
  withRouter,
  withAuthUser
)(LikeActivityList);

function NotificationsModal(props) {
  return (
    <div>
      <Modal isOpen={props.show} toggle={props.onShow} className="modal-dialog modal-lg">
        <ModalBody>
          {!!props.likeItems.length && 
            <div>
              <div className="d-flex bd-highlight mt-3">
                <div className="h5 bd-highlight mr-auto">Likes</div>
                {/* <div className="bd-highlight ml-2">
                  <button onClick={props.onClearLikes} className="btn btn-primary btn-sm rounded-0 align-self-center">Clear</button>
                </div> */}
              </div>
              <LikeActivity 
                likeItems={props.likeItems}
                profiles={props.likesProfiles}
                authUid={props.authUserId}
                onShow={props.onShow}
              />
            </div>
          }
          <div className="h5 mt-4">Follow Requests</div>
          {!!props.socialItems.length? 
          <div>
          <ProfilesList 
            profiles={props.socialItems} 
            authUid={props.authUserId} 
            isPending={true}/>
            </div>
            :
            <div className="text-center">
            <hr/>
            <FiUserCheck  size="3em" />
            <div>No Pending Requests</div>
            </div>
          }
        </ModalBody>
      </Modal>
    </div>
  );
}

export default compose(
  withAuthUser,
  withFirebase
)(NotificationsBadge);