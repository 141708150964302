import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFirebase } from '../Firebase';
import { LydsFeed } from '../Lyds';
const imgDivStyle = {
  width: '150px',
  height: '150px',
  borderColor: 'lightgray',
  borderStyle: 'solid',
  borderWidth: '1px'
}

const ExplorePage = props => {
  const [numPosts, setNumPosts] = useState(0);
  // console.log('ExplorePage... props.props =', props.posts, tag)
  
  useEffect(() => {
    // console.log('ExplorePage.useEffect... props =', props)
    setNumPosts(props.posts.length);
  }, [props.posts.length]);

  const followersTotal = 0;
  return (

    <div className="container mt-3"> 
      <div className="row no-gutters">
        <div className="col-md-3">
        {false && <img className="rounded-circle" style={imgDivStyle} src="https://pixnio.com/free-images/2018/06/06/2018-06-06-15-22-20-1200x900.jpg" alt={props.stream} />}
        </div>
        <div className="col-md-9">
          <div className="card-body">
            <div className="d-flex bd-highlight mb-3">
              <div className="bd-highlight align-self-center h3 font-weight-light pr-2">{props.stream}</div>
              {props.socialButton && 
                <div className="bd-highlight align-self-center col-5">
                  {props.socialButton}
                </div>
              }
              {/* <div className="ml-auto p-2 bd-highlight">Flex item</div> */}
            </div>
            <div className="card-text">
            <div className="d-flex bd-highlight">
              <div className="bd-highlight mr-3"><strong>{numPosts}</strong><span> post{numPosts === 1? '' : 's'}</span></div>
              {false && <div className="bd-highlight mx-3" style={followersTotal? {cursor: "pointer"} : null } onClick={followersTotal? props.onClickSocialNet('followers') : null}>
                  <strong>{followersTotal}</strong><span> follower{followersTotal === 1? '' : 's'}</span>
              </div>}
            </div>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <LydsFeed />
    </div>
  )
}

const mapStateToProps = state => ({
  posts: state.lydsState.posts,
  stream: state.lydsState.stream,
});

export default compose(
withRouter,
withFirebase,
connect(
  mapStateToProps,
)
)(ExplorePage);
