import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { FiLogOut } from 'react-icons/fi';

function SignOutButton(props) {
  
  const onLogout = event => {
    props.firebase.doSignOut();
    props.onSetLyds([]);
  }

  return (
    <button 
      className="btn btn-secondary " 
      type="button" 
      onClick={onLogout}>
      Log Out <FiLogOut size="1.5em" />
    </button>
  );
}

const mapDispatchToProps = dispatch => ({
  onSetLyds: posts => dispatch({type: 'LYDS_SET', posts}),
});

export default compose(
  withFirebase,
  connect(
    null,
    mapDispatchToProps,
  )
)(SignOutButton);