import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FiChevronsDown, FiChevronsUp, FiMoreVertical, FiTrash2, FiExternalLink } from 'react-icons/fi';
import { FaRegCommentAlt } from 'react-icons/fa';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { PlayPauseButton } from '../../containers/LydPlayerControls'
import LinkedCaption from './LinkedCaption';
import LikeButton, { TotalUserLikes } from '../Social/LikeButton';
import { writtenDate } from '../../utils';
import { LinkedSourceIcon, PlayPauseIcon } from '../../icons';
import _ from 'lodash';

export const ArtistNames = ({ style, artists, delimiter }) => {
  var artistNames = artists? artists.join(delimiter) : 'unknown';

  return <p style={style}>{artistNames}</p>
}

export const HashTagsList = ({ hashtags }) => {
  return _.map(hashtags, (hashtag, key) => {
    return (
      <span key={key}>{hashtag? `#${hashtag}` : null} </span>
    );
  });
}

const LydItemActions = props => {
  // console.log("LydItemActions... ", props)
  const isAuthUser = props.authUser && 
                     (props.lyd.userId === props.authUser.uid);
  if (!isAuthUser) return null;
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleShow = () => setShow(!show);

  const onDelete = event => {
    if (window.confirm(`Delete post?\n'${props.lyd.title}'`)) {
      props.firebase.deletePost(props.lyd.lydId)
        .then(onDeleteCompletion)
        .catch(err => console.error(err));
    }
  }

  // TODO: We don't need to do all this if we had used firebase.postsRefOn (which attaches a listener) instead of firebase.postsRefOnce. Refactor later.
  const onDeleteCompletion = () =>  {
    const posts = [...props.posts];
    const removedPost = _.remove(posts, (lyd, idx) => {
      const toRemove = (lyd.lydId === props.lyd.lydId);
      if (toRemove) {
        const nextIdx = (idx + 1) % props.posts.length;
        props.onSetLyd(props.posts[nextIdx]);
      }
      return toRemove;
    });
    props.onSetLyds(posts);
  };

  const editItems = isAuthUser? (
    <div className={props.className}>
      {/* <DropdownItem divider/> */}
      <DropdownItem onClick={onDelete}><FiTrash2 className="mr-1 align-text-bottom" size="1.2em"/> Delete Post</DropdownItem>
    </div>
  ) : null;

  return (
    <Dropdown 
      direction="left"
      group isOpen={show} 
      size="sm" 
      toggle={toggleShow}>
      <DropdownToggle
        tag="span"
        onClick={toggleShow}
        data-toggle="dropdown"
        aria-expanded={show}
      >
        <FiMoreVertical size="1.3em" />
      </DropdownToggle>
      <DropdownMenu>
        {editItems}
      </DropdownMenu>
    </Dropdown>
  );
}

const LydItem = props => {
  const {
    title,
    artists,
    caption,
    hashtags,
    dateAdded,
    source,
    lydId,
  } = props.lyd;

  const [infoExpanded, setInfoExpanded] = useState(true);

  const expandInfo = event => {
    console.log(!infoExpanded);
    setInfoExpanded(!infoExpanded)
    console.log(!infoExpanded);
  }
  const user = props.user;
  const userThumbnail = user? user.alias_image || user.photoURL : '';
  const userName = user? user.aliasName || user.alias_name || user.username : '...';
  const isCurrent = !!props.currentLyd && (props.currentLyd.lydId === lydId);
  const bgColor = (props.playing && isCurrent)? 
                    'bg-animated' :
                    (isCurrent? 'bg-secondary' : 'bg-white');
  const titleBtnColor = (props.playing && isCurrent)? 
                    'bg-outline-primary' :
                    (isCurrent? 'bg-white' : 'bg-primary');
  const titleTextColor = (props.playing && isCurrent)? 
                    'text-primary' :
                    (isCurrent? 'text-primary' : 'text-white');

  const InfoBox = props => {
    return (
      <div>
        <LinkedCaption linkClassName="nounderline text-primary" hashtags={hashtags || []} bgColor={bgColor}>
          {caption || ''}
        </LinkedCaption>
      </div>
    );
  }
  
  return (
    <div className="card mt-2" id={lydId}>
      <div className="card-header py-2">
      <div className="d-flex bd-highlight">
        <div className="bd-highlight">
          <Link to={`/${userName}`}>
            <img className="rounded-circle alias-thumbnail" src={userThumbnail} />
          </Link>
        </div>
        <div className="bd-highlight align-self-center">
          <Link to={`/${userName}`} className="nounderline text-dark px-2">{userName}</Link>
        </div>

        <div className="ml-auto bd-highlight">
          <LydActions lyd={props.lyd} />
        </div>
      </div>
      </div>
      <div className={`card-body ${bgColor}`}>
        <div className="d-flex align-items-center pb-2">
          {/* <div className="btn btn-outline-secondary rounded-0 align-self-center btn-sm mr-2" onClick={expandInfo}>
            {infoExpanded? <FiChevronsUp size="1.5em" className="text-primary"/> : <FiChevronsDown size="1.5em" className="text-primary"/>}
          </div> */}
          <PlayPauseButton 
              customStyle={{borderWidth: "1px", borderStyle: "solid", cursor: "pointer"}}
              className={`${titleTextColor} text-left ${titleBtnColor} px-2`}
              lyd={props.lyd}
              playing={props.playing && isCurrent}
            > 
            <div style={{fontSize: "1.3rem"}} className="lead"><PlayPauseIcon className={`${titleTextColor} ${titleBtnColor} align-middle pb-1`} size="1.2em" playing={props.playing && isCurrent} /> {title}</div>
          </PlayPauseButton> 
          <div className="ml-auto"><LikeButton className="ml-2" lyd={props.lyd} /></div>
        </div>
        {artists?<span className="h6 card-subtitle my-1">{artists.join('•')}</span> : null}
        
        <div className="d-flex flex-row bd-highlight pt-2">
          {/* <div className="pr-2 bd-highlight mt-1"><FaRegCommentAlt size="1.4em" /></div>
          <div className="px-1 bd-highlight"><FiShare size="1.5em"/></div>
          <div className="pl-2 bd-highlight"><a className="text-dark" href={props.lyd.source} target="FiExternalLink size="1.5em"/></a></div> */}
        </div>
        <TotalUserLikes lyd={props.lyd} />
        <hr/>
        <div className="mt-2">
          <LinkedCaption linkClassName="nounderline text-primary" hashtags={hashtags || []} bgColor={bgColor} >
          {caption || ''}
          </LinkedCaption>
        </div>
        <div className="small text-muted pt-2">
        {writtenDate(dateAdded)} <a className="align-middle nounderline text-info" href={source} target="_blank"> <FiExternalLink className="text-muted small mb-1" size="1.6em"/></a>
        </div>
      </div>
    </div>
  );
}

export default LydItem;

function DeletePostButton(props) {

  const onDelete = event => {
    if (window.confirm(`Delete post?\n'${props.lyd.title}'`)) {
      props.firebase.deletePost(props.lyd.lydId)
        .then(onDeleteCompletion)
        .catch(err => console.error(err));
    }
  }

  // TODO: We don't need to do all this if we had used firebase.postsRefOn (which attaches a listener) instead of firebase.postsRefOnce. Refactor later.
  const onDeleteCompletion = () =>  {
    const posts = [...props.posts];
    const removedPost = _.remove(posts, (lyd, idx) => {
      const toRemove = (lyd.lydId === props.lyd.lydId);
      if (toRemove) {
        const nextIdx = (idx + 1) % props.posts.length;
        props.onSetLyd(props.posts[nextIdx]);
      }
      return toRemove;
    });
    props.onSetLyds(posts);
  };

  if (!props.authUser || 
      (props.lyd.userId !== props.authUser.uid)) {
    return null;
  }
  
  return <button onClick={onDelete}>Delete</button>;
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  posts: state.lydsState.posts,
});

const mapDispatchToProps = dispatch => ({
onSetLyds: posts => dispatch({type: 'LYDS_SET', posts}),
onSetLyd: lyd => dispatch({type: 'LYD_SET', lyd}),
});

const DeleteButton = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withFirebase
)(DeletePostButton);

const LydActions = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withFirebase
)(LydItemActions);