import React, { useState, useEffect  } from 'react';
import { connect } from 'react-redux';
import { compose, onlyUpdateForPropTypes } from 'recompose';
import { withFirebase } from '../Firebase';
import { useDebounce } from '../../hooks';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function ProfileChangeField(props) {
  const { 
    hasParent,
    inputOnly,
    inputType,
    isRequired,
    onChange,
    onSubmitForm, 
    showModal,
    onShowModal,
    onProceed,
    fieldValue,
    fieldName,
    fieldId,
    placeholder,
    isValid,
    validMsg,
    isLoading,
  } = props;

  const parsedValidation = fieldValue? (isValid? 'valid' : 'invalid') : '';

  const renderFormBody = () => {
    return (
      <div className="bd-highlight">
        {(inputType === 'textarea')?
        (<div className="form-group">
          <label htmlFor={fieldId}>{fieldName}</label>
          <textarea 
            style={{backgroundColor: "transparent"}}
            value={fieldValue}
            required={isRequired}
            onChange={onChange}
            placeholder={placeholder}
            className={`form-control ${!parsedValidation? '' : `is-${parsedValidation}`}`} 
            id={fieldId} 
            rows="3" 
          />
          <div className={!parsedValidation? '' : `${parsedValidation}-feedback`} >
            <div className="d-flex justify-content-between">
              <span className="bd-highlight">{validMsg}</span>
            </div>
          </div>
        </div>)
        :
        (<div className="form-label-group">
          <input 
            style={{backgroundColor: "transparent"}}
            id={fieldId}
            className={`form-control ${!parsedValidation? '' : `is-${parsedValidation}`}`} 
            value={fieldValue}
            name={fieldName}
            onChange={onChange}
            type={inputType || "text"}
            placeholder={placeholder}
            required={isRequired}
          />
          <label htmlFor={fieldId}>{placeholder}</label>
          <div className={!parsedValidation? '' : `${parsedValidation}-feedback`} >
            <div className="d-flex justify-content-between">
              <span className="bd-highlight">{validMsg}</span>
            </div>
          </div>
        </div>)
        }
      </div>
    );
  }

  return (
    <div>
      {hasParent? 
        renderFormBody()
      :
      <form className="d-flex flex-row bd-highlight was-invalidated"
        onSubmit={onSubmitForm}
      >
        {renderFormBody()}
        {!inputOnly &&
        <div className="bd-highlight">
          <button 
            className="btn btn-sm btn-secondary ml-2 mt-2"
            disabled={isRequired? !fieldValue : false}
            type="submit"
          >
            {isLoading? 
            <div className="spinner-border spinner-border-sm" role="status"></div> :
            'Change'
            }
          </button>
        </div>
        }
      </form>
      }
      <FeedbackModal 
        showHeader
        title={`${fieldName} Update`}
        show={showModal} 
        onShow={onShowModal} 
        cancelLabel="Cancel"
        confirmLabel="Ok"
        onConfirm={onProceed(true)} 
        onCancel={onProceed(false)} 
      >
        {props.children}
      </FeedbackModal>
    </div>
  );
}

function FullnameChangeForm(props) {
  const [fullname, setFullname] = useState('');
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [validMsg, setValidMsg] = useState(null);
  
  const onSubmitForm = event => {
    event.preventDefault();
    if (props.skipConfirm) {
      onProceed(true)(event);
    } else {
      setShowModal(true);
    }
  }

  const onComplete = () => {
    setIsValid(true);
    setValidMsg('Name succesfully changed!');
    setLoading(false);
  }

  const onFinalSubmit = event => {
    event.preventDefault();
    setLoading(true);
    props.firebase
      .setProfileProp('fullname', fullname, onComplete)
      .catch(error => {
        console.log(error);
        setIsValid(false);
        setValidMsg(String.raw`◔̯◔ Failed to change name. Please try again.`);
        setLoading(false);
      })
  };
  
  const onProceed = proceed => event => {
    if (proceed) {
      onFinalSubmit(event);
    }
    setShowModal(false);
  }

  const onShowModal = event => {
    setShowModal(!showModal);
  }

  const onChange = event => {
    if (props.onChange) props.onChange(event);
    setFullname(event.target.value);

    if (props.authUser && 
        (event.target.value === props.authUser.displayName)) {
      setValidMsg('That\'s currently your full name.');
      setIsValid(false);
    } else {
      setValidMsg(null);
      setIsValid(true);
    }
  };

  return (
    <div>
      <ProfileChangeField 
        inputOnly={props.inputOnly}
        onChange={onChange}
        onSubmitForm={onSubmitForm} 
        showModal={showModal}
        onShowModal={onShowModal}
        onProceed={onProceed}
        fieldName="Full Name"
        fieldValue={fullname}
        fieldId="inputFullname"
        placeholder={props.placeholder}
        isValid={isValid}
        validMsg={validMsg}
        isLoading={loading}
      >
        <div>
          <div className="">Change your name?:</div>
          <div className="font-weight-bold">{fullname}</div>
        </div>
      </ProfileChangeField>
    </div>
  );
}

function FeedbackModal(props) {
  return (
    <div>
      <Modal isOpen={props.show} toggle={props.onShow} className="modal-dialog modal-lg">
        {props.showHeader &&
        <ModalHeader toggle={props.onShow} className="text-center">
          <span className="text-capitalize">
            {props.title}
          </span>
        </ModalHeader>
        }
        <ModalBody>
            {props.children}
        </ModalBody>
        <ModalFooter>
          {props.onCancel && 
          <button className="btn btn-outline-danger rounded-0" onClick={props.onCancel}>
              {props.cancelLabel}
          </button>
          }
          {props.onConfirm && 
          <button className="btn btn-primary rounded-0" onClick={props.onConfirm}>
              {props.confirmLabel}
          </button>
          }
        </ModalFooter>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

const FullnameField = compose(
  withFirebase,
  connect(mapStateToProps),
)(FullnameChangeForm);

function WebsiteChangeForm(props) {
  const [website, setWebsite] = useState('');
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [validMsg, setValidMsg] = useState(null);
  
  const onSubmitForm = event => {
    event.preventDefault();
    if (props.skipConfirm) {
      onProceed(true)(event);
    } else {
      setShowModal(true);
    }
  }

  const onComplete = () => {
    setIsValid(true);
    setValidMsg('Website succesfully changed!');
    setLoading(false);
  }

  const onFinalSubmit = event => {
    event.preventDefault();
    setLoading(true);
    
    props.firebase
      .setProfileProp('website', website, onComplete)
      .catch(error => {
        console.log(error);
        setIsValid(false);
        setValidMsg(String.raw`◔̯◔ Failed to change website. Please try again.`);
        setLoading(false);
      })
  };
  
  const onProceed = proceed => event => {
    if (proceed) {
      onFinalSubmit(event);
    }
    setShowModal(false);
  }

  const onShowModal = event => {
    setShowModal(!showModal);
  }

  const onChange = event => {
    if (props.onChange) props.onChange(event);
    setWebsite(event.target.value);
    setValidMsg(null);
    setIsValid(event.target.value !== '');
  };

  return (
    <div>
      <ProfileChangeField 
        inputType="text"
        inputOnly={props.inputOnly}
        onChange={onChange}
        onSubmitForm={onSubmitForm} 
        showModal={showModal}
        onShowModal={onShowModal}
        onProceed={onProceed}
        fieldName="Website"
        fieldValue={website}
        fieldId="inputWebsite"
        placeholder={props.placeholder}
        isValid={isValid}
        validMsg={validMsg}
        isLoading={loading}
      >
        <div>
          <div className="">Change your website?:</div>
          <div className="font-weight-bold">{website}</div>
        </div>
      </ProfileChangeField>
    </div>
  );
}

const WebsiteField = compose(
  withFirebase,
  connect(mapStateToProps),
)(WebsiteChangeForm);

function BioChangeForm(props) {
  const [bio, setBio] = useState('');
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [validMsg, setValidMsg] = useState(null);
  
  const onSubmitForm = event => {
    event.preventDefault();
    if (props.skipConfirm) {
      onProceed(true)(event);
    } else {
      setShowModal(true);
    }
  }
  
  const onComplete = () => {
    setIsValid(true);
    setValidMsg('Bio succesfully changed!');
    setLoading(false);
  }

  const onFinalSubmit = event => {
    event.preventDefault();
    setLoading(true);
    props.firebase
    .setProfileProp('bio', bio, onComplete)
    .catch(error => {
      console.log(error);
      setIsValid(false);
      setValidMsg(String.raw`◔̯◔ Failed to change bio. Please try again.`);
      setLoading(false);
    })
  };
  
  const onProceed = proceed => event => {
    if (proceed) {
      onFinalSubmit(event);
    }
    setShowModal(false);
  }

  const onShowModal = event => {
    setShowModal(!showModal);
  }

  const onChange = event => {
    if (props.onChange) props.onChange(event);
    setBio(event.target.value);
    setValidMsg(null);
    setIsValid(event.target.value !== '');
  };

  return (
    <div>
      <ProfileChangeField 
        inputType="textarea"
        inputOnly={props.inputOnly}
        onChange={onChange}
        onSubmitForm={onSubmitForm} 
        showModal={showModal}
        onShowModal={onShowModal}
        onProceed={onProceed}
        fieldName="Bio"
        fieldValue={bio}
        fieldId="inputBio"
        placeholder={props.placeholder}
        isValid={isValid}
        validMsg={validMsg}
        isLoading={loading}
      >
        <div>
          <div className="card">
            <div className="m-2">{bio}</div>
          </div>
        </div>
      </ProfileChangeField>
    </div>
  );
}

const BiographyField = compose(
  withFirebase,
  connect(mapStateToProps),
)(BioChangeForm);

export { 
  BiographyField,
  FullnameField,
  ProfileChangeField,
  WebsiteField,
 }