import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FiUser, FiHome, FiPlus, FiSearch, FiHeart } from 'react-icons/fi';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import PostLyd from '../PostLyd';
import NotificationsBadge from '../Notifications';
import { Collapse, NavbarToggler } from 'reactstrap';
import * as ROUTES from '../../constants/routes';
import { HomeIcon } from '../../icons';


const NavProfileLink = compose(withRouter, withFirebase)(props => {

  const [active, setActive] = useState(false);
  const [username, setUsername] = useState(null);
  const [currentUid, setCurrentUid] = useState(null);
  const toProfilePath = name => ROUTES.PROFILE.replace(':username', name);

  useEffect(() => {
    // setLoading(true);
    props.firebase.getUidFromUsername(props.username).then(setCurrentUid);
  }, [props.username])

  // Listen for any changes to username.
  useEffect(() => {
    if (!currentUid) return;
    const usernameRef = props.firebase.db.ref(`users/${currentUid}/username`);
    usernameRef.on('value', ss => setUsername(ss.val()));
    
    return () => usernameRef.off('value');
  }, [currentUid])

  useEffect(() => {
    setActive(props.location.pathname === toProfilePath(username));
  }, [props.location.pathname]);

  return (
    <div className={active? props.className + ' active' : props.className}>
      <div className="d-flex bd-highlight justify-content-center">
        <Link className={`bd-highlight ${active? "text-white active" : "text-primary"}`}
          to={toProfilePath(username)}>
          <FiUser size="2em"/> 
        </Link>
        <NotificationsBadge />
      </div>
      <div><Link to={toProfilePath(username)} className={`nounderline initialism lead ${active? "text-white" : "text-primary"}`}>Profile</Link></div>
    </div>
  );
});

const NavHomeLink = withRouter(props => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(props.location.pathname === ROUTES.LANDING);
  }, [props.location.pathname]);

  return (
    <div className={active? props.className + ' active' : props.className}>
      <Link className={`h3 nounderline ${active? "text-white" : "text-primary"}`}
        to={ROUTES.LANDING}>
        <HomeIcon className={active? 'homeicon-active' : 'homeicon'} />
      </Link>
      <div><Link to={ROUTES.LANDING} className={`nounderline initialism lead ${active? "text-white" : "text-primary"}`}>Home</Link></div>
    </div>
  );
})

function NavSearchButton(props) {
  return (
    <div className={props.className}>
      <FiSearch size="2em"/> 
  </div>
  );
}

function NavAddButton(props) {
  return (
    <div>
      <PostLyd className={props.className}>
        <FiPlus size="2em"/>
      </PostLyd>
    </div>
  );
}

function NavNotificationsButton(props) {
  return (
    <div className={props.className}>
      <FiHeart size="2em"/> 
      <span className="badge badge-danger ml-n3">3</span>
    </div>
  )
}

const Navigation = ({ authUser, location, match }) => {
  const [isOpen, setIsOpen] = useState(false);
  // console.log(authUser, location, match); 
  if (!authUser && 
      [ROUTES.LANDING, 
       ROUTES.SIGN_IN, 
       ROUTES.SIGN_UP]
      .includes(location.pathname)) 
    return null;

  if (!authUser) return <NavigationNonAuth />;

  return (
    <nav className="navbar navbar-expand-sm navbar-light bg-white border-bottom">
      <NavbarToggler onClick={e => setIsOpen(!isOpen)} />
      <Link className="navbar-brand text-dark bg-secondary px-2 font-weight-light" to={ROUTES.LANDING}>lyddy</Link>
      <Collapse isOpen={isOpen} navbar>
        <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
          <li className="nav-item">
            <Link className="nav-link" to={ROUTES.LANDING}>Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={`/${authUser.displayName}`}>Profile</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={ROUTES.ACCOUNT}>Account</Link>
          </li>
        </ul>
        {/* <form className="form-inline my-2 my-lg-0">
          <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
          <button className="btn btn-outline-secondary rounded-0 my-2 my-sm-0" type="submit">Search</button>
        </form> */}
      </Collapse>
    </nav>
  );

  if (authUser) {
    return <NavigationAuth authUser={authUser} />;
  } else {
    return <NavigationNonAuth />;
  }
}

const ulStyle = {
  listStyleType: "none",
margin: 0,
padding: 0,
overflow: "hidden",
backgroundColor: "honeydew",
}
const liStyle = {
  float: "left",
  color: "pink",
  textAlign: "center",
  padding: "14px 16px",
  textDecoration: "none",
  fontSize: "18px",
  fontWeight: "bold"
}
const headStyle = {
  position: "fixed",
  borderBottom: "1px solid #E7E7E7",
  left: "0",
  top: "0",
  height: "60",
  width: "100%"
}
  
{/* <li className="nav-item">
<a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
</li> */}
const NavigationAuth = ({ authUser }) => (
  <div style={headStyle}>
    <ul style={ulStyle}>
      <li className="nav-item">
        <Link style={liStyle} to={ROUTES.LANDING}>Home</Link>
      </li>
      <li className="nav-item">
        <Link style={liStyle} to={`/${authUser.displayName}`}>Profile</Link>
      </li>
      <li className="nav-item">
        <Link style={liStyle} to={ROUTES.ACCOUNT}>Account</Link>
      </li>
    </ul>
  </div>
);

const NavigationNonAuth = () => (
  <div style={headStyle}>
    <ul style={ulStyle}>
      <li className="nav-item">
        <Link style={liStyle} to={ROUTES.LANDING}>Log in</Link>
      </li>
      <li className="nav-item">
        <Link style={liStyle} to={ROUTES.SIGN_UP}>Sign up</Link>
      </li>
    </ul>
  </div>
);

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});



export { NavProfileLink, NavSearchButton, NavAddButton, NavHomeLink, NavNotificationsButton };

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Navigation);
