import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useClipboard } from '../../hooks';
import { SubmitSourceModal } from '../PostLyd';

function ClipboardReadPrompt(props) {

  const pasteEventCallback = url => {
    setShowModal(!!url)
  }

  const allowClipboard = props.userSettings? props.userSettings.allowClipboard : false;
  const [clipboardContent, clipboardError] = useClipboard(pasteEventCallback);
  const [showModal, setShowModal] = useState(false);

  if (!allowClipboard) return null;
  
  if (clipboardError) {
    console.error(clipboardError.message)
    return (
      <div className="bg-danger">
        <div style={{color: 'white'}}>
          <p>Oops! {clipboardError.message}</p>
          <button onClick={() => setShowModal(false)}>Close</button>
        </div>
      </div>
    );
  }

  if (!clipboardContent) return null;

  console.log("clipboardContent:", clipboardContent, showModal)
  return (
      <SubmitSourceModal 
        modalTitle="New Post"
        show={showModal} 
        toggleModal={e => setShowModal(!showModal)}
        post={{ source: clipboardContent }}
      />
  );

}

const mapStateToProps = state => ({
  userSettings: state.sessionState.authUser? state.sessionState.authUser.settings : null
});

export default connect(mapStateToProps)(ClipboardReadPrompt);