import { useReducer } from 'react';

export const STAGE_POST = 'STAGE_POST';
export const RESET_POST = 'RESET_POST';
export const INITIAL_POST = {
  source: '',
  title: '',
  authors: '',
  caption: '',
  hashtags: [],
  type: '',
  liked_by: [],
};

function reducer(state = INITIAL_POST, action) {
  switch (action.type) {
    case STAGE_POST:
    return {
      ...state, 
      ...action.post
    };
    case RESET_POST:
    default:
      return { ...INITIAL_POST };
  }
}

function usePostReducer(initPost) {
  const [post, dispatch] = useReducer(reducer, initPost || INITIAL_POST);
  const dispatchPost = post => {
    if (post) {
      dispatch({type: STAGE_POST, post})
    } else {
      dispatch({type: RESET_POST})
    }
  }

  return [post, dispatchPost]
}

export default usePostReducer;
