import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const LinkedCaption = props => {
  const [moreCaption, setMoreCaption] = useState(false);
  if (!props.children) return null;
  // const [selectedTags, setSelectedTags] = useState([]);
  
  // Support multiple selection of hashtags (via Shift key)
  // const handleOnClickTag = tag => event => {
  //   if (event.shiftKey) {
  //     event.preventDefault();
  //     if (!selectedTags.includes(tag)) setSelectedTags([...selectedTags, tag])
  //   }
  // }
  
  let lines = props.children.split('\n');
 

  const toggleMoreButton = (
    (lines.length > 1) && 
    !moreCaption && // Make the button disappear.
    <button className={`border-0 ${props.bgColor? props.bgColor : "bg-white"} text-info`}
            onClick={e => setMoreCaption(!moreCaption)}>
            {moreCaption? 'less' : 'more'}...
    </button>
  );

  if (!moreCaption) {
    lines = lines.slice(0,1)
  }

  const linkedCaption = _.map(lines, (line, idx) => {
    let words = line.split(' ');
    const taggedWords = _.map(words, 
      (chars, idx) => {
      const key = `${chars}_${chars.length}${idx}`;
      const tag = chars.replace('#', '').toLowerCase();
      const isTag = !!props.hashtags && props.hashtags.includes(tag);
      return (
        <span key={key}>
          {isTag? 
            <Link className={props.linkClassName} to={`/explore/tags/${tag}`}>{`${chars}`}</Link> : chars
          }{' '}
        </span>
      );
    });
    return (
      <div key={idx}>
        {taggedWords}{(idx === 0) && toggleMoreButton}
      </div>
    );
  });
  return linkedCaption;
}

const LinkedCaptionNonToggle = props => {
  if (!props.children) return null;
  
  const lines = props.children.split('\n');
  const linkedCaption = _.map(lines, (line, idx) => {
    let words = line.split(' ');
    const taggedWords = _.map(words, 
      (chars, idx) => {
      const key = `${chars}_${chars.length}${idx}`;
      const tag = chars.replace('#', '').toLowerCase();
      const isTag = !!props.hashtags && props.hashtags.includes(tag);
      return (
        <span key={key}>
          {isTag? 
            <Link to={`/explore/tags/${tag}`}>{`${chars}`}</Link> : chars
          }{' '}
        </span>
      );
    });
    return (
      <div className="text-truncate" key={idx}>
        {taggedWords}
      </div>
    );
  });
  return linkedCaption;
}

export default LinkedCaption;