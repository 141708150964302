import React, { useState } from 'react';
import {
  FiPlayCircle, 
  FiPauseCircle,
  FiFile, 
  FiYoutube,
  FiEye,
  FiEyeOff,
  FiPlay
} from 'react-icons/fi';
import { IoLogoVimeo, IoIosPlay, IoIosPause, IoIosMic, IoIosRadio } from 'react-icons/io';
import { FaMixcloud, FaSoundcloud, FaBandcamp  } from 'react-icons/fa';
import { getSourceType } from '../utils';

export const PlayPauseIcon = ({ circle, size, playing, className }) => {
  const playIcon = (circle? <FiPlayCircle className={className} size={size}/> : <FiPlay className={className} size={size}/>);
  const pauseIcon = (circle? <FiPauseCircle className={className} size={size}/> : <IoIosPause className={className} size={size}/>);
  
  return playing ? pauseIcon : playIcon;
}

export function PlayPauseSpinnerIcon({ size, isPlaying, played, onTogglePlay, className }) {
  return (
    <div>
    {isPlaying && !(played > 0)? 
      <div className="spinner-border text-dark mt-1 ml-1 mr-1" onClick={onTogglePlay} role="status"></div> 
      :
      <button className="rounded-2 border-0 bg-white" onClick={onTogglePlay}>
        <PlayPauseIcon className={className} size={size} playing={isPlaying} /> 
      </button>
    }
    </div>
  );
}

export function LinkedSourceIcon(props) {
  const { sourceType, srcUrl, children, ...rest } = props;
  const srcType = sourceType? 
                  sourceType : 
                  getSourceType(srcUrl);
                  
  return (
    <a href={srcUrl} target="_blank">
      <SourceIcon {...rest} sourceType={srcType}>{children}</SourceIcon>
    </a>
  );
}

export function SourceIcon(props) {
  const { sourceType, children, ...rest } = props;
  switch (props.sourceType) {
    case 'mic':
      return (<IoIosMic {...rest} >{children}</IoIosMic>);
    
    case 'live':
      return (<IoIosRadio {...rest} >{children}</IoIosRadio>);
    
    case 'youtube':
      return (<FiYoutube {...rest} >{children}</FiYoutube>);
    
    case 'mixcloud':
      return (<FaMixcloud {...rest} >{children}</FaMixcloud>);
    
    case 'vimeo':
      return (<IoLogoVimeo {...rest} >{children}</IoLogoVimeo>);
    
    case 'soundcloud':
      return (<FaSoundcloud {...rest} >{children}</FaSoundcloud>);
    
    case 'bandcamp':
      return (<FaBandcamp {...rest} >{children}</FaBandcamp>);
    
    default:
      return (<FiFile {...rest} >{children}</FiFile>);      
  }
}

export function EyeToggleIcon(props) {
  const { show, ...rest } = props;
  if (props.show) {
    return <FiEye {...rest} />
  } else {
    return <FiEyeOff {...rest} />
  }
}
export function AudioBarsIcon({ active }) {
  const className = `audiobar${active? '' : ' noAnim'}`;
  return (
    <span className="audiobar-c">
      <div id="audiobar-1" className={className}></div>
      <div id="audiobar-2" className={className}></div>
      <div id="audiobar-3" className={className}></div>
      <div id="audiobar-4" className={className}></div>
      <div id="audiobar-5" className={className}></div>
    </span>
  );
}
export function SkipForwardIcon(props) {
    return (
      <svg 
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-skip-forward">
      <polygon points="5 4 15 12 5 20 5 4"></polygon>
      <line 
        x1="19"
        y1="5"
        x2="19"
        y2="19">
      </line>
      </svg>
    );
}

export function HomeIcon(props) {
  console.log(props.className)
  return (
    <svg version="1.1" 
         id="Layer_1" 
         xmlns="http://www.w3.org/2000/svg" 
         xmlnsXlink="http://www.w3.org/1999/xlink" 
         x="0px" 
         y="0px"
         viewBox="0 0 100 100" 
        //  enableBackground="new 0 0 100 100" 
         xmlSpace="preserve"
         width="42"
         height="42"
    >
<g id="XMLID_2_">
	<path id="XMLID_17_" className={props.className} d="M24,12c0,5-8,5-8,10c0,5,8,5,8,10
		c0,5-8,5-8,10c0,5,8,5,8,10c0,5-8,5-8,10c0,5,8,5,8,10c0,5-8,5-8,10s8,5,8,10"/>
</g>
<g id="XMLID_1_">
	<path id="XMLID_15_" className={props.className} d="M84,12c0,5-8,5-8,10c0,5,8,5,8,10
		c0,5-8,5-8,10c0,5,8,5,8,10c0,5-8,5-8,10c0,5,8,5,8,10c0,5-8,5-8,10s8,5,8,10"/>
</g>
<g id="XMLID_5_">
	<path id="XMLID_13_" className={props.className} d="M54,12c0,5-8,5-8,10c0,5,8,5,8,10
		c0,5-8,5-8,10c0,5,8,5,8,10c0,5-8,5-8,10c0,5,8,5,8,10c0,5-8,5-8,10s8,5,8,10"/>
</g>
    </svg>
  )
}
export function SkipBackIcon(props) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-skip-back">
      <polygon points="19 20 9 12 19 4 19 20"></polygon>
      <line 
        x1="5"
        y1="19"
        x2="5"
        y2="5">
      </line>
    </svg>
  );
}

export function PauseIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-pause">
      <rect 
        x="6"
        y="4"
        width="4"
        height="16">
      </rect>
      <rect 
        x="14"
        y="4"
        width="4"
        height="16">
      </rect>
    </svg>
  );
}

export function HeartIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="22" 
      height="22" 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      className="feather feather-heart"
    >
    <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
    </svg>
  );
}


export function MoreIcon(props) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      className="feather feather-more-vertical"><circle cx="12" 
      cy="12" 
      r="1"></circle><circle cx="12" 
      cy="5" 
      r="1"></circle><circle cx="12" 
      cy="19" 
      r="1"></circle>
    </svg>
  );
}

export function TrashIcon(props) {
  if (props.withLines) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        className="feather feather-trash-2">
        <polyline points="3 6 5 6 21 6"></polyline>
        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
        <line 
          x1="10" 
          y1="11" 
          x2="10" 
          y2="17">
        </line>
        <line 
          x1="14" 
          y1="11" 
          x2="14" 
          y2="17">
        </line>
      </svg>
    );
  } else {
    return (
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="24"  
        height="24"   
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="1" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        className="feather feather-trash">
          <polyline points="3 6 5 6 21 6"></polyline>
          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
          <line 
            x1="10" 
            y1="11" 
            x2="10" 
            y2="17">
          </line>
          <line 
            x1="14" 
            y1="11" 
            x2="14" 
            y2="17">
          </line>
      </svg>
    );
  }
}

export function LockIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        className="feather feather-lock">
      <rect 
        x="3" 
        y="11" 
        width="18" 
        height="11" 
        rx="2" 
        ry="2"
      >
      </rect>
      <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
    </svg>
  );
}