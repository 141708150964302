import { useState, useEffect } from 'react';
import { canPlay } from '../utils';

const readClipboardContent = event => {
  // console.log(event)
  if (navigator.clipboard) {
    try {
      return navigator.clipboard.readText(); 
    } catch(error) {
      console.error(error) 
    }
  } 
  
  if (event && event.clipboardData) {
    const text = event.clipboardData.getData('text/plain');
    return Promise.resolve(text);
  }

  const error = { message: 'clipboard not supported', code: 'CLIPBOARD_NO_SUPPORT'};
  return Promise.reject(error);
}

function useClipboard(callback) {
  const [clipboardContent, setClipboardContent] = useState(null);
  const [clipboardError, setClipboardError] = useState(null);

  useEffect(() => {
    window.addEventListener('paste', pasteEventHandler);
    return () => window.removeEventListener('paste', pasteEventHandler);
  });

  const pasteEventHandler = event => {
    readClipboardContent(event)
      .then(url => {
        if (canPlay(url)){ 
          setClipboardContent(url);
          if (callback) callback(url);
        } else {
          setClipboardContent(null);
        }
      })
      .catch(setClipboardError);
  };

  return [clipboardContent, clipboardError]
}

export default useClipboard;