import React, { useState, useEffect } from 'react';
import { getFollowStatusName } from '../../actions/social';
import { withFirebase } from '../Firebase';
import * as SOCIAL from '../../constants/social';

function SocialButton(props) {
  const [socialItem, setSocialItem] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props.firebase
      .getSocialNetConnection(props.uid, 'followers')
      .then(status => {
        setSocialItem(status);
        setLoading(false);
      });
  }, [props.uid])

  const onFollow = event => {
    event.preventDefault()
    setLoading(true);
    props.firebase.performFollowAction(props.uid, socialItem.status)
      .then(newStatus => {
        setSocialItem(newStatus);
        if (props.onFollowCb) props.onFollowCb(event)(newStatus, props.uid);
        setLoading(false);
      });
  }


  const btnStyle = (socialItem.status === SOCIAL.UNFOLLOW_CODE)? 
                    "btn-secondary"
                    : "btn-outline-primary";

  return (
    <button 
      className={`btn ${btnStyle} btn-block text-capitalize ${props.className}`}  
      onClick={onFollow}
      disabled={loading}
    >
      {loading? 
      <div className="spinner-border spinner-border-sm" role="status"></div> :
      getFollowStatusName(socialItem.status)
      }
    </button>
  )
}

export default withFirebase(SocialButton)