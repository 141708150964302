export const FUNNY_FACES = [
  String.raw`♪┏(・◡･)┛♪`,
  String.raw`♪┏(・◡･)┛♪`,
  String.raw`♪┗ ( ･◡･) ┓♪`, 
  String.raw`♪┗ (･◡･ ) ┓♪`, 
  String.raw`♪┏(･◡･ )┛♪`,
  String.raw`♪┏(･◡･ )┛♪`,
  String.raw`♪┗ (･◡･ ) ┓♪`, 
  String.raw`♪┏(・◡･)┛♪`,
  String.raw`♪┏(・o･)┛♪`,
  String.raw`\( ﾟoﾟ)/`,
  String.raw`(/◔ ◡ ◔)/`,
  String.raw`\(• ◡ •)/`,
  String.raw`◕ ◡ ◕`,
  String.raw`(▰˘◡˘▰)`,
  String.raw`(>'o’)> ♥ <('o’<)`,
  String.raw`( ‘-’)人(ﾟ_ﾟ )`,
  String.raw`( ^_^)=mm=(^_^ )`,
  String.raw`⎝⏠⏝⏠⎠`,
  String.raw`ಠ_ಠ`,
  String.raw`Ծ_Ծ`,
  String.raw`ಠ~ಠ`,
  String.raw`ఠ_ఠ`,
  String.raw`ಠ_ರೃ`,
  String.raw`ಠ╭╮ಠ`,
  String.raw`◔_◔`,
  String.raw`𝄇⥀.⥀𝄆`,
  String.raw`⊙_ʘ`,
  String.raw`◴_◶`,
  String.raw`◔̯◔`,
  String.raw`⊙︿⊙`,
  String.raw`◕︵◕`,
  String.raw`●︵•`,
  String.raw`◉︵◉`,
  String.raw`ಡ_ಡ`,
  String.raw`ಥ_ಥ`,
  String.raw`ಢ_ಢ`,
  String.raw`ಢ_ಥ`,
  String.raw`⊙﹏⊙`,
  String.raw`( ﾟoﾟ)`,
  String.raw`⋋_⋌`,
  String.raw`〴⋋_⋌〵`,
  String.raw`ಠ益ಠ`,
  String.raw`ヽ(｀Д´)ﾉ`,
  String.raw`(︶ε︶メ)`,
  String.raw`˚▱˚`,
  String.raw`⊙▂⊙`,
  String.raw`⊙▃⊙`,
  String.raw`(ΘεΘ;)`,
  String.raw`(ﾟヮﾟ)`,
  String.raw`〓D`,
  String.raw`(ﾟ＊ﾟ)`,
  String.raw`(｡･_･｡)`,
  String.raw`♥╭╮♥`,
  String.raw`≖◡ಎ≖`,
  String.raw`(///_ಥ)`,
  String.raw`(≥_<)`,
  String.raw`ʕʘ‿ʘʔ`,
  String.raw`:-þ`,
  String.raw`ヘ(◕。◕ヘ)`,
  String.raw`๏_๏`,
  String.raw`◉_◉`,
  String.raw`☼_☼`,
  String.raw`♨_♨`,
  String.raw`(ﾟーﾟ)`,
  String.raw`(• ε •)`,
  String.raw`¬_¬`,
  String.raw`ಸ_ಸ`,
  String.raw`ↁ_ↁ`,
  String.raw`ಆ_ಆ`,
  String.raw`ಊ_ಊ`,
  String.raw`ಹ_ಹ`,
  String.raw`ㅎ_ㅎ`,
  String.raw`【•】_【•】`,
  String.raw`(ு८ு)`,
  String.raw`(づ｡◕‿‿◕｡)づ`,
  String.raw`⊂(◉‿◉)つ`,
  String.raw`ح˚௰˚づ`,
  String.raw`╚(•⌂•)╝`,
  String.raw`☜-(ΘLΘ)-☞ `,
  String.raw`☜｡◕‿◕｡☞`,
  String.raw`щ（ﾟДﾟщ） `,
  String.raw`ლ(ಠ_ಠ ლ)`,
  String.raw`◖|◔◡◉|◗`,
  String.raw`(Ō_ƆŎ) `,
  String.raw`≖_≖ `,
  String.raw`(•̪●)`,
  String.raw`⊛ठ̯⊛ `,
  String.raw`ತಟತ`,
  String.raw`˚⌇˚`,
  String.raw`ಈ_ಈ `,
  String.raw`ⓧ_ⓧ`,
  String.raw`⨂_⨂`,
  String.raw`✖_✖`,
  String.raw`×̯×`,
  String.raw`‹•.•›`,
  String.raw`•ﺑ•`,
  String.raw`(० ्०)`,
  String.raw`ôヮô`,
  String.raw`!⑈ˆ~ˆ!⑈`,
  String.raw`۹ↁﮌↁ`,
  String.raw`乂◜◬◝乂`,
  String.raw`ತ_ಎತ`,
  String.raw`ಠﭛಠ`,
  String.raw`⇎_⇎`,
  String.raw`句_句`,
  String.raw`໖_໖`,
  String.raw`༺‿༻`,
  String.raw`ಠ , ಥ`,
  String.raw`१|˚–˚|५`,
  String.raw`โ๏௰๏ใ ื`,
  String.raw`◷_௰◴`,
  String.raw`◎ܫ◎`,
  String.raw`(˚ㄥ_˚)`,
  String.raw`(˚இ˚)`,
  String.raw`ộ_ộ`,
  String.raw`◘_◘ `,
  String.raw`δﺡό`,
  String.raw`⊂•⊃_⊂•⊃`,
  String.raw`ح˚ᆺ˚ว`,
  String.raw`❂‿❂ `,
  String.raw`(ΘL_Θ)`,
  String.raw`●¿_●`,
  String.raw`《〠_〠》`,
  String.raw`¯\_(ツ)_/¯`,
  ]