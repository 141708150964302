import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { FiMonitor, FiXCircle } from 'react-icons/fi';
import Duration from '../../containers/Duration';
import { PlayPauseSpinnerIcon } from '../../icons';
import { getSourceType } from '../../utils';
import { SourceIcon } from '../../icons';

function AudioPreviewCard(props) {
  const srcType = props.sourceType || getSourceType(props.url);
  const playerRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [playedToggle, setPlayedToggle] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [duration, setDuration] = useState(0);
  
  const onProgress = state => {
    if (!seeking) {
      setPlayed(state.played)
    }
  }
  const onDuration = duration => {
    setDuration(duration)
  }
  const onSeekMouseDown = event => {
    setSeeking(true)
  }
  const onSeekChange = event => {
    setPlayed(parseFloat(event.target.value))
  }
  const onSeekMouseUp = event => {
    setSeeking(false)
    playerRef.current.seekTo(parseFloat(event.target.value))
  }

  const toggleDuration = event => {
    setPlayedToggle(playedToggle? 0 : 1)
  }

  
  const TimeSlider = props => {
    return (
      <div className="d-flex justify-content-between bd-highlight mb-3">
        <div className="p-2 bd-highlight">
          <input
            type='range' min={0} max={1} step='any'
            value={played}
            onMouseDown={onSeekMouseDown}
            onChange={onSeekChange}
            onMouseUp={onSeekMouseUp}
          />
        </div>
        
        <div className="p-2 bd-highlight">
          <div 
            onClick={toggleDuration}>
              <Duration 
                className="bg-white"
                seconds={duration * Math.abs(playedToggle - played)}
                isRemaining={!!playedToggle} 
              />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="card text-center">

      <div className="card-header d-flex justify-content-between">
        <div className="bd-highlight lead text-capitalize">
          <SourceIcon sourceType={srcType} size="1.1em" className="mr-2"/> 
          {props.headerTitle? props.headerTitle : `${srcType} audio preview`}
        </div>
        {props.onClose &&
        <div className="bd-highlight">
          <FiXCircle onClick={props.onClose} className="bg-light text-dark" size="1.4em" />
        </div>
        }
      </div>
      
      <div className="card-body">
      <div className="d-flex justify-content-around bd-highlight mb-3">
        <div className="p-2 bd-highlight">
          <div onClick={e => setPlaying(!playing)}>
            <PlayPauseSpinnerIcon isPlaying={playing} played={played} size="2.5em" className="text-dark" />
          </div>
        </div>
        
        <div className="p-2 bd-highlight">
          <TimeSlider />
        </div>
        
      {(srcType !== 'file') && 
        <div className="p-2 bd-highlight">
          <div onClick={e => setShowVideo(!showVideo)}>
            <FiMonitor size="1.4em" className={`text-${showVideo? "primary": "dark"}`} />
          </div>
        </div>
      }
      </div>
      <ReactPlayer
        ref={playerRef}    
        className='react-player'
        playing={playing}
        width={showVideo? "100%" : 0}
        height={showVideo? "100%" : 0}
        url={props.url}
        controls={false}
        onStart={props.handlsStart}
        onError ={props.handlError}
        onProgress={onProgress}
        onDuration={onDuration}
      />
      </div>
    </div>
  )
}

export default AudioPreviewCard;