import React from 'react';
import withLoadingSpinner from '../Loading';
import LydItem from './LydItem';
import { MdHearing } from 'react-icons/md';

const LydList = ({ posts, profiles, currentLyd, isPlaying, emptyMessage }) => {
  return (
    <div>
    {!posts.length && emptyMessage? 
      <div className="my-4 text-center">
      <MdHearing size="3em" />
      <div className="h4 mt-2">{emptyMessage}</div>
    </div> 
    :
    posts.map(post => {
      return ( // TODO: required PropType! 
        <LydItem 
          key={post.lydId}
          lyd={post}
          user={profiles[post.userId]}
          currentLyd={currentLyd}
          playing={isPlaying}
        />
      );
    })
    }
    </div>
  );
}

export default withLoadingSpinner(LydList);