import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import SignInPage from '../SignIn';
import { withRouter } from 'react-router-dom';
import { LydsFeed } from '../Lyds';
import withLoadingSpinner from '../Loading';
import { SIGN_IN } from '../../constants/routes';
import { withEmailVerification } from '../Session';

const SignIn = withLoadingSpinner(SignInPage);

function LandingPage(props) {
  if (props.isAuthenticating) return null;
  
  if (!props.authUser) {
    props.history.push(SIGN_IN);
    return null;
  }

  return (
    <div>
      <LydsFeed />
    </div>
  )
}

const mapStateToProps = state => {
  const { authUser, isAuthenticating } = state.sessionState;
  return { authUser, isAuthenticating };
}
export default compose(
  withEmailVerification,
  withLoadingSpinner,
  withRouter,
  connect(mapStateToProps)
  )(LandingPage);
