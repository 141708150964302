import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useDebounce } from '../../hooks';
import { canPlay, getSourceType } from '../../utils';
import { FiHelpCircle, FiChevronDown, FiChevronUp, FiXCircle } from 'react-icons/fi';
import { HelpCard } from '../About';
import { SourceIcon } from '../../icons';
import AudioPreviewCard from './AudioPreviewCard';
import { MdHelpOutline, MdHelp } from 'react-icons/md';

function AddSourceUrl(props) {
  // console.log("props.prefill=", props.prefill)
  const inputElement = useRef(null);
  const [sourceUrl, setSourceUrl] = useState(props.prefill || '');
  const [error, setError] = useState(null);
  const [showHelp, setShowHelp] = useState(false);
  const [validUrl, setValidUrl] = useState(() => canPlay(props.prefill));
  const debouncedUrl = useDebounce(sourceUrl, 500);
  const [sourceType, setSourceType] = useState(null);

  useEffect(() => {
    const playable = canPlay(debouncedUrl);
    if (playable) {
      setSourceType(getSourceType(debouncedUrl));
      setShowHelp(false);
    }
    setValidUrl(playable);
  },[debouncedUrl]);

  useEffect(() => {
    setSourceUrl(props.prefill);
    inputElement.current.value = props.prefill;
  },[props.prefill]);

  const onClearUrl = event => {
    event.preventDefault();
    setSourceUrl('');
    inputElement.current.value = '';
  }
  
  const onChange = event => {
    setSourceUrl(event.target.value);
  };

  const onAdd = event => {
    setSourceUrl(debouncedUrl);
    props.onAddSource(debouncedUrl);
  }
  
  const handlePlayerError = event => {
    setError({message: 'Error in playing URL source', error: event})
    console.warn('There was an error in ReactPlayer!', event)
  }

  const ClearAddComboButton = props => {
    const notReady = (sourceUrl === '');
    // TODO: Move these to custom CSS!
    let btnStyle = 'btn btn-primary';
    if (notReady) {
      btnStyle = 'btn btn-outline-primary';
    } else if (!validUrl) {
      btnStyle = 'btn btn-outline-danger';
    } 

    return (
      <div className={props.className}>
      <button 
        type={validUrl? "submit" : null}
        hidden={notReady}
        onClick={validUrl? null : onClearUrl}
        className={btnStyle}
      >
        {(!notReady && !validUrl)? 'Clear' : 'Add...' }
      </button>
    </div>
    )
  }
  const isInvalid = (debouncedUrl === '')? false : (!validUrl);

  return (
    <div>
      <form className={`d-flex bd-highlight ${validUrl? 'was-validated' : ''}`} 
        onSubmit={onAdd}>
        <div className="bd-highlight mr-2">
          <input 
            type="url"
            ref={inputElement} 
            className={`form-control w-100 ${isInvalid? 'is-invalid' : ''}`} 
            id="validationTextarea" 
            placeholder="http://"
            onChange={onChange}
          />
          <div className="invalid-feedback">
            <div className="d-flex justify-content-between">
              <span className="bd-highlight">This URL is not a playable source</span>
            </div>
          </div>
        </div>
        <ClearAddComboButton className="bd-highlight flex-shrink-1" />
        <div className="text-primary bd-highlight pl-2 pt-1 align-self-top" style={{cursor: "pointer"}} onClick={e => setShowHelp(!showHelp)}>
          {showHelp? <MdHelp className="text-primary" size="1.3em"/> : <MdHelpOutline className="text-muted" size="1.3em"/>}
        </div>
      </form>
      <div className="pt-2"> {showHelp && <HelpCard/>}</div>
      {validUrl && <AudioPreviewCard onClose={onClearUrl} sourceType={sourceType || 'file'} url={sourceUrl} />}
    </div>
  );
}

export default AddSourceUrl;