export const LANDING = '/';
export const SIGN_UP = '/account/emailsignup';
export const SIGN_IN = '/account/login';
export const HOME = '/home';
export const ACCOUNT = '/account/edit';
export const ADMIN = '/admin';
export const ABOUT_US = '/about/us';
export const ABOUT_RELEASES = '/about/release-notes';
export const ABOUT_GUIDELINES = '/about/guidelines';
export const ABOUT_SUPPORT = '/about/support';
export const ABOUT_TERMS = '/about/terms';
export const ABOUT_LIBRARIES = '/about/libraries';
export const ADMIN_DETAILS = '/admin/:uid';
export const PASSWORD_FORGET = '/account/password/reset';
export const PROFILE ='/:username';
export const USER_SAVED ='/:username/saved';
export const USER_TAGGED ='/:username/tagged';
export const EXPLORE_HASHTAGS ='/explore/tags/:hashtag';
export const EXPLORE ='/explore';
export const USER_SOCIAL_NET ='/:username/:social(followers|following)';