import { getSortedPosts } from '../utils';

export const INITIAL_STATE = {
  posts: [],
  lyd: null,
  playing: false,
  stream: '',
  reversed: false
};

const applySetLyds = (state, action) => ({
  ...state,
  posts: getSortedPosts(action.posts),
});

const applySetLyd = (state, action) => {
  return {
    ...state,
    lyd: action.lyd,
  };
};

function lydsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LYD_REMOVE': {
      let posts = [ ...state.posts ];
      const idx = posts.findIndex(p => p.lydId === action.lydId);
      if (idx === 0){
        posts.shift();
      } else if (idx === (posts.length - 1)) {
        posts.pop();
      } else {
        const a = posts.slice(0, idx);
        const b = posts.slice(idx + 1);
        posts = a.concat(b);
      }
      return { ...state, posts };
    }
    case 'LYDS_UPDATE': {
      let posts = [ ...state.posts ];
      const currentPostIds = state.posts.map(p => p.lydId);
      action.posts.forEach(post => {
        if (!currentPostIds.includes(post.lydId)) {
          posts.push(post);
        }
      });
      return { ...state, posts: getSortedPosts(posts) };
    }
    case 'LYDS_SET': {
      return applySetLyds(state, action);
    }
    case 'LYD_SET': {
      return applySetLyd(state, action);
    }
    case 'PLAYING_SET': {
      return {...state, playing: action.playing};
    }
    case 'SET_STREAM': {
      return {...state, stream: action.stream};
    }
    case 'TOGGLE_REVERSED': {
      return {...state, reversed: !state.reversed};
    }
    default:
      return state;
  }
}

export default lydsReducer;