import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, Tooltip } from 'reactstrap';
import { compose } from 'recompose';
import AddSourceUrl from './AddSourceUrl';
import ClipboardReadPrompt from './ClipboardReadPrompt';
import SubmitSource from './SubmitSource';
import { withAuthorization } from '../Session';
import { SearchYoutube } from '../SearchBar'
import { usePostReducer } from '../../hooks';
import { withAuthUser } from '../Session';
import { FiLink, FiSearch } from 'react-icons/fi';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
export { ClipboardReadPrompt };

function StagedComponents(props) {  
  const [currIndex, setCurrIndex] = useState(0);
  const [showComponent, setShowComponent] = useState(false);
  const [post, dispatchPost] = usePostReducer();
  const StagedComponent = props.components[currIndex];

  useEffect(() => {
    setShowComponent(props.modal);
  }, [props.modal])

  const handleClickNext = () => {
    if (currIndex < props.components.length - 1) {
      setCurrIndex(currIndex + 1);
    } else {
      setShowComponent(false);
    }
  };
  
  const handleFinish = () => {
    dispatchPost();
    setCurrIndex(0);
    setShowComponent(false);
  }

  return (
    <div>
      <StagedComponent
        step={currIndex + 1}
        onClickNext={handleClickNext}
        onFinish={handleFinish}
        show={showComponent}
        toggleModal={props.toggle}
        onStage={post => dispatchPost(post)}
        post={post}
        authUser={props.authUser}
      />
    </div>
  );
}

export function CaptureSource(props) {
  const [post, dispatchPost] = usePostReducer();
  const [activeTab, setActiveTab] = useState('1');

  const onAddUrl = sourceUrl => {
    if (sourceUrl !== post.source) {
      props.onStage({ source: sourceUrl });
    } else {
      props.onStage(post);
    }

    props.onClickNext();
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const onSelectYoutube = (sourceURL, title, thumbnail, description) => {
    const newPost = {
      source: sourceURL,
      title
    };
    if (thumbnail) {
      newPost['image'] = thumbnail;
    }
    if (description) {
      newPost['caption'] = description;
    }
    dispatchPost(newPost);
    props.onStage(newPost);
    props.onClickNext();
  }

  if (!props.show) return null;
  return (
    <div>
      <Nav className="justify-content-center" tabs>
        <NavItem style={{cursor: "pointer"}}>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggleTab('1'); }}
          >
            <FiLink size="1.4em" /> Add URL
          </NavLink>
        </NavItem>
        <NavItem style={{cursor: "pointer"}}>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggleTab('2'); }}
          >
            <FiSearch size="1.5em" /> Search Youtube
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div className="mt-4 d-flex justify-content-center">
            <AddSourceUrl onAddSource={onAddUrl} prefill={post.source} />
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className="mt-4 d-flex justify-content-center">
            <SearchYoutube onSelect={onSelectYoutube} urlInputOnError={false} />
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
}

function CaptureSourceModal(props) {
  return (
    <div>
      {/* {console.log("CaptureSourceModal ... props =", props)} */}
      <Modal isOpen={props.show} toggle={props.toggleModal} className="modal-dialog modal-lg" >
        <ModalHeader toggle={props.toggleModal}><span className="lead">Step {props.step}: Get Media Source</span></ModalHeader>
        <ModalBody>
          <CaptureSource {...props} />
        </ModalBody>
      </Modal>
    </div>
  );
}

export function SubmitSourceModal(props) {
  // console.log("SubmitSourceModal ... props=", props)
  const modalTitle = props.modalTitle? 
                      props.modalTitle : 
                     (props.step? `Step ${props.step}: Edit Post` : 'Edit Post' );
  return (
    <div>
      <Modal isOpen={props.show} toggle={props.toggleModal} className="modal-dialog modal-lg">
        <ModalHeader toggle={props.toggleModal}><span className="lead">{modalTitle}</span></ModalHeader>
        <ModalBody>
          <SubmitSource 
            {...props} 
            toggleModal={props.toggleModal} 
            onFinish={props.onFinish}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}

function SourceSubmitterStaged(props) {
  // console.log("SourceSubmitter... props =", props)
  return (
    <div>
      <StagedComponents components={[CaptureSourceModal, SubmitSourceModal]} {...props}/>
    </div>
  );
}

const SourceSubmitter = compose(
  withAuthUser,
  withAuthorization(authUser => !!authUser),
)(SourceSubmitterStaged);

function SourceSubmitterModals(props) {
  const [modal, setModal] = useState(false);

  const toggle = event => {
    setModal(!modal);
  }

  return (
    <div style={{cursor: "pointer"}}>
      <div className={props.className}>
          <div 
            style={{borderWidth: "2px"}} 
            className="btn btn-sm btn-outline-primary rounded-1" 
            onClick={toggle}
          >
            {props.children}
          </div>
      </div>
      <div onClick={toggle} className="initialism text-center lead mt-n2 mb-1">New Post</div>
      <SourceSubmitter modal={modal} toggle={toggle}/>
    </div>
  );
}

export default SourceSubmitterModals;

