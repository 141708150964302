import React, { useState, useEffect  } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { useDebounce } from '../../hooks';
import { isValidUsername } from '../../utils';
import { ProfileChangeField } from '../Account/EditProfile';
const UPDATE_USERNAME_ERROR = 'UPDATE_USERNAME_ERROR';

function UsernameChangeForm(props) {
  const FIELD_NAME = 'username';
  const [username, setUsername] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [validMsg, setValidMsg] = useState(null);

  const debouncedName = useDebounce(username, 400);

  useEffect(() => {
    if (isValid && debouncedName) {
      props.firebase.usernameExists(debouncedName)
      .then(exists => {
        if (exists) {
          setError({
            code: UPDATE_USERNAME_ERROR,
            message: `${debouncedName} is already taken`
          });
          setValidMsg(String.raw`◔̯◔ ${debouncedName} is already taken`);
          setIsValid(false);
        } else {
          setValidMsg(`${debouncedName} is available!`);
          setIsValid(true);
        }
      });
    }
  },[debouncedName]);

  const onSubmitForm = event => {
    event.preventDefault();
    if (props.skipConfirm) {
      onProceed(true)(event);
    } else {
      setShowModal(true);
    }
  }

  const onFinalSubmit = event => {
    event.preventDefault();
    setLoading(true);
    if (!isValid) return;
    props.firebase.doChangeUsername(username)
    .then(() => {
      // setUsername('');
      setIsValid(true);
      setValidMsg('Username succesfully changed!');
      if (props.onUpdated) props.onUpdated(FIELD_NAME, username)(event);
      setLoading(false);
    })
    .catch(err => {
      console.log(err)
      setIsValid(false);
      setValidMsg(String.raw`◔̯◔ Failed to change name. Please try again.`);
      setLoading(false);
    });
  };

  const onProceed = proceed => event => {
    if (proceed) {
      onFinalSubmit(event);
    }
    setShowModal(false);
  }

  const onShowModal = event => {
    setShowModal(!showModal);
  }

  const onSubmit = event => {
    event.preventDefault();

    if (!window.confirm(`Changing your username to\n${username}`)) {
      return;
    }
    
    props.firebase.doChangeUsername(username)
      .then(() => {
        setUsername('');
        setIsValid(true);
        setValidMsg('Username succesfully changed!');
        props.onChange()
      })
      .catch(err => {
        console.log(err)
        setError(err);
        setIsValid(false);
        setValidMsg(String.raw`◔̯◔ Failed to change name. Please try again.`);
      });
  };

  const onChange = event => {
    if (props.onChange) props.onChange(event);
    const inputText = event.target.value.toLowerCase();
    setUsername(inputText);
    // console.log(inputText, isValidUsername(inputText))
    let inputError = null;
    // if (!inputText)
    if (!!inputText && /\s+/.test(inputText)) {
      inputError = {
        code: UPDATE_USERNAME_ERROR, 
        message: 'No spaces allowed'
      };
      setValidMsg('No spaces allowed');
      setIsValid(false);
    } else if (!!inputText && !isValidUsername(inputText)) {
      inputError = { 
        code: UPDATE_USERNAME_ERROR, 
        message: 'Sorry, that username is not valid' 
      };
      setValidMsg('Sorry, that username is not valid');
      setIsValid(false);
    } 
    // else if (props.authUser && 
    //           (inputText === props.authUser.displayName)) {
    //   inputError = {
    //     code: UPDATE_USERNAME_ERROR,
    //     message: 'That\'s currently your username ;-)' 
    //   };
    //   setValidMsg('That\'s currently your username ;-)');
    //   setIsValid(false);
    // } 
    else {
      setValidMsg(null);
      setIsValid(true);
    }

    setError(inputError);
    // setIsValid(inputError === null);
  };

  return (
    <div>
    <ProfileChangeField 
      hasParent={props.hasParent}
      inputOnly={props.inputOnly}
      onChange={onChange}
      onSubmitForm={onSubmitForm} 
      showModal={showModal}
      onShowModal={onShowModal}
      onProceed={onProceed}
      fieldName={FIELD_NAME}
      fieldValue={username}
      fieldId="inputUsername"
      placeholder={props.placeholder}
      isValid={isValid}
      validMsg={validMsg}
      isLoading={loading}
    >
      <div>
        <div className="">Change your username?:</div>
        <div className="font-weight-bold">{username}</div>
      </div>
    </ProfileChangeField>
  </div>
  );
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  withFirebase,
  connect(mapStateToProps),
)(UsernameChangeForm);