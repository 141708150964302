import React, { Component, useEffect, useState, useRef, useCallback} from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import SignOutButton from '../SignOut';
import { withFirebase } from '../Firebase';

import UsernameChangeFormBase from '../UsernameChange';
import { FullnameField, BiographyField, WebsiteField, ProfileChangeField } from './EditProfile';
import PasswordChangeForm from '../PasswordChange';
import { usePrevious } from '../../hooks';
import { MdHelpOutline, MdHelp } from 'react-icons/md';

const SIGN_IN_METHODS = [
  {
    id: 'password',
    provider: null,
  },
  {
    id: 'google.com',
    provider: 'googleProvider',
  },
  {
    id: 'facebook.com',
    provider: 'facebookProvider',
  },
  {
    id: 'twitter.com',
    provider: 'twitterProvider',
  },
];

const AccountPage = ({ authUser }) => {
  // TODO: Refactor this! It's horribly implemented.
  const editProfileRef = useRef(null);
  const changePasswordRef = useRef(null);
  const privacyRef = useRef(null);
  const [activeRef, setActiveRef] = useState(null);
  const previousRef = usePrevious(activeRef);
  document.title = 'Lyddy | Account';
  useEffect(() => {
    editProfileRef.current.className = 'tab-pane fade show active';
    setActiveRef(editProfileRef);
  }, [])

  useEffect(() => {
    if (activeRef) {
      activeRef.current.className = 'tab-pane fade show active';
    }
    if (previousRef) {
      previousRef.current.className = 'tab-pane fade';
    }
  }, [activeRef])

  const onSelected = event => {
    if (!event.target.className.includes('active')) {
      event.target.className = event.target.className + ' active';
    } else {

    }
    const nodeId = event.target.id.replace('-tab', '');
    switch (nodeId) {
      case editProfileRef.current.id:
        setActiveRef(editProfileRef);
        return;
      case changePasswordRef.current.id:
        setActiveRef(changePasswordRef);
        return;
      case privacyRef.current.id:
        setActiveRef(privacyRef);
        return;
      default:
        setActiveRef(editProfileRef);
    }
  }

  return (
    <div className="card mx-auto" style={{maxWidth: "900px"}}>
      <div className="card-header d-flex justify-content-between">
        <div className="bd-highlight lead">My Account <span className="font-weight-bold">@{authUser.displayName}</span></div>
        <div className="bd-highlight"><SignOutButton /></div>
      </div>
      {/* <div className="row ml-1"> */}
        <div className="">
          <div className="nav nav-pills justify-content-center" id="v-pills-tab" role="tablist" >
            <a style={{cursor: "pointer"}} className={activeRef && (activeRef.current.id === 'v-pills-profile')? "nav-link active text-white rounded-0" : "nav-link"} id="v-pills-profile-tab" data-toggle="pill" onClick={onSelected} role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</a>
            <a style={{cursor: "pointer"}}  className={activeRef && (activeRef.current.id === 'v-pills-password')? "nav-link active text-white rounded-0" : "nav-link"} id="v-pills-password-tab" data-toggle="pill" onClick={onSelected} role="tab" aria-controls="v-pills-password" aria-selected="false">Password</a>
            <a style={{cursor: "pointer"}}  className={activeRef && (activeRef.current.id === 'v-pills-privacy')? "nav-link active text-white rounded-0" : "nav-link"} id="v-pills-privacy-tab" data-toggle="pill" onClick={onSelected} role="tab" aria-controls="v-pills-privacy" aria-selected="false">Privacy</a>
          </div>
        {/* </div> */}
        <div className="border">
          <div className="tab-content" id="v-pills-tabContent">
            <div ref={editProfileRef} className="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
              <EditProfile authUser={authUser}/>
            </div>
            <div ref={changePasswordRef} className="tab-pane fade" id="v-pills-password" role="tabpanel" aria-labelledby="v-pills-password-tab">
              <EditPassword />
            </div>
            <div ref={privacyRef} className="tab-pane fade" id="v-pills-privacy" role="tabpanel" aria-labelledby="v-pills-privacy-tab">
              <EditPrivacy />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function EditProfile(props) {
  return (
    <div className="d-flex flex-column bd-highlight m-4">
      <div className="bd-highlight"><div className="h4">Profile</div></div>
      <PublicProfileToggle authUser={props.authUser} />
      <UsernameChangeFormBase isRequired placeholder="New Username" />
      <FullnameField placeholder="Full Name"/>
      <WebsiteField placeholder="Website"/>
      <hr/>
      <BiographyField placeholder="Write your bio here..."/>
    </div>
  );
}

function EditPassword(props) {
  return (
    <div className="m-4">
      <div className="h4">Password</div>
      <PasswordChangeForm />
      {/* <hr />
      <PasswordForgetLink /> */}
    </div>
  )
}

function EditPrivacy(props) {
  return (
    <div className="m-4">
      <div className="h4">Privacy</div>
      <ClipboardPermissionsToggle />
    </div>
  )
}
const PublicProfileLabel = props => {
  const publicOrPrivate = (props.isPublic === null? '' : (props.isPublic? 'Public' : 'Private'))
  return (
    <label>
      Profile visibility: <b>{publicOrPrivate} </b>
    </label>
  );
}

function PublicProfileToggleBase(props) {
  const [isPublic, setIsPublic] = useState(null);

  useEffect(() => {
    props.firebase.getProfilePublic().then(publicStatus => {
        setIsPublic(publicStatus)
      })
  }, [isPublic])

  const onToggle = event => {
    props.firebase.setProfilePublic(!isPublic)
    setIsPublic(!isPublic)
  }

  const publicOrPrivate = (isPublic === null? '' : (isPublic? 'Public' : 'Private'))

  return (
    <div className="d-flex flex-row bd-highlight mb-3">
      <div className="bd-highlight text-align-middle align-self-center">Profile visibility: </div>
      <div className="bd-highlight ml-2">
        {publicOrPrivate && 
        <button onClick={onToggle} type="button" className={`btn btn-sm rounded-pill ${isPublic? "btn-primary" : "btn-outline-primary"}`} data-toggle="button" aria-pressed={isPublic? "false" : "true"} autoComplete="off">
          {publicOrPrivate}
        </button>
        }
      </div>
    </div>
  );
}

const PublicProfileToggle = withFirebase(PublicProfileToggleBase);

const ClipboardPermissionsToggle = withFirebase(props => {
  const [enableClipboard, setEnableClipboard] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(null);

  useEffect(() => {
    props.firebase.getSettings('allowClipboard')
      .then(allow => {
        setEnableClipboard(allow || false)
      });
  }, [])

  const onToggle = event => {
    props.firebase.setSettings('allowClipboard', !enableClipboard)
      .then(() => setEnableClipboard(!enableClipboard))
  }

  const enabledDisabled = (enableClipboard === null? 
                           '' : 
                           (enableClipboard? 'ON' : 'OFF'))
                           
                           
  return (
    <div className="d-flex flex-row bd-highlight mb-3">
      <div className="p-2 bd-highlight text-align-middle align-self-center" id="clipboardAccessLabel">Paste-to-Post: </div>
      <Tooltip placement="top" isOpen={tooltipOpen} target="clipboardAccessLabel" toggle={e => setTooltipOpen(!tooltipOpen)}>
        Allow clipboard access to quickly add new posts by copy/pasting a URL
      </Tooltip>
      <div className="p-2 bd-highlight">
        {enabledDisabled && 
        <button onClick={onToggle} type="button" className={`btn btn-sm rounded-pill ${enableClipboard? "btn-primary" : "btn-outline-primary"}`} data-toggle="button" aria-pressed={enableClipboard? "true" : "false"} autoComplete="off">
          {enabledDisabled}
        </button>
        }
      </div>
      {tooltipOpen? 
      <MdHelp style={{cursor: "pointer"}} className="mx-auto bd-highlight align-self-center " onClick={e => setTooltipOpen(!tooltipOpen)} size="1.2em" />
      :
      <MdHelpOutline style={{cursor: "pointer"}} className="mx-auto bd-highlight align-self-center " onClick={e => setTooltipOpen(!tooltipOpen)} size="1.2em" /> 
      }
    </div>
  );
});

class LoginManagementBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSignInMethods: [],
      error: null,
    };
  }

  componentDidMount() {
    this.fetchSignInMethods();
  }

  fetchSignInMethods = () => {
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then(activeSignInMethods =>
        this.setState({ activeSignInMethods, error: null }),
      )
      .catch(error => this.setState({ error }));
  };

  onSocialLoginLink = provider => {
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onDefaultLoginLink = password => {
    const credential = this.props.firebase.emailAuthProvider.credential(
      this.props.authUser.email,
      password,
    );

    this.props.firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onUnlink = providerId => {
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  render() {
    const { activeSignInMethods, error } = this.state;

    return (
      <div>
        Sign In Methods:
        <ul>
          {SIGN_IN_METHODS.map(signInMethod => {
            const onlyOneLeft = activeSignInMethods.length === 1;
            const isEnabled = activeSignInMethods.includes(
              signInMethod.id,
            );

            return (
              <li key={signInMethod.id}>
                {signInMethod.id === 'password' ? (
                  <DefaultLoginToggle
                    onlyOneLeft={onlyOneLeft}
                    isEnabled={isEnabled}
                    signInMethod={signInMethod}
                    onLink={this.onDefaultLoginLink}
                    onUnlink={this.onUnlink}
                  />
                ) : (
                  <SocialLoginToggle
                    onlyOneLeft={onlyOneLeft}
                    isEnabled={isEnabled}
                    signInMethod={signInMethod}
                    onLink={this.onSocialLoginLink}
                    onUnlink={this.onUnlink}
                  />
                )}
              </li>
            );
          })}
        </ul>
        {error && error.message}
      </div>
    );
  }
}

const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}) =>
  isEnabled ? (
    <button
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
    >
      Deactivate {signInMethod.id}
    </button>
  ) : (
    <button
      type="button"
      onClick={() => onLink(signInMethod.provider)}
    >
      Link {signInMethod.id}
    </button>
  );

class DefaultLoginToggle extends Component {
  constructor(props) {
    super(props);

    this.state = { passwordOne: '', passwordTwo: '' };
  }

  onSubmit = event => {
    event.preventDefault();

    this.props.onLink(this.state.passwordOne);
    this.setState({ passwordOne: '', passwordTwo: '' });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      onlyOneLeft,
      isEnabled,
      signInMethod,
      onUnlink,
    } = this.props;

    const { passwordOne, passwordTwo } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return isEnabled ? (
      <button
        type="button"
        onClick={() => onUnlink(signInMethod.id)}
        disabled={onlyOneLeft}
      >
        Deactivate {signInMethod.id}
      </button>
    ) : (
      <form onSubmit={this.onSubmit}>
        <input
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="New Password"
        />
        <input
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Con firm New Password"
        />

        <button disabled={isInvalid} type="submit">
          Link {signInMethod.id}
        </button>
      </form>
    );
  }
}

const LoginManagement = withFirebase(LoginManagementBase);

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

// const UsernameChangeForm = connect(mapStateToProps)(UsernameChangeFormBase)


const condition = authUser => !!authUser;
export default compose(
  withEmailVerification,
  connect(mapStateToProps),
  // withEmailVerification,
  withAuthorization(condition),
)(AccountPage);
