import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { guessedTitleArtist, getHashtags, getSourceType } from '../../utils';
import { usePostReducer } from '../../hooks';
import PreviewAudio from './AudioPreviewCard';
import _ from 'lodash';

function SubmitSource(props) {
  const [post, dispatchPost] = usePostReducer();
  const [title, setTitle] = useState('');
  const [isGuessing, setIsGuessing] = useState(false);
  const [authors, setAuthors] = useState('');
  const [caption, setCaption] = useState(post.caption);
  const [hashtags, setHashtags] = useState(post.hashtags);
  const [image, setImage] = useState(post.image);

  useEffect(() => {
    dispatchPost(props.post);
  }, [props.post.source])

  useEffect(() => {
    if (post.title) {
      setIsGuessing(true);
      guessedTitleArtist(post.title)
        .then(([title, artist]) => {
          setAuthors(artist);
          setTitle(title);
          setIsGuessing(false);
        });
    }
  }, [post.title]);

  const onSubmit = event => {
    event.preventDefault();
    const newPost = {
      title,
      authors,
      artists: [authors], 
      caption,
      hashtags,
      type: getSourceType(post.source),
      source: post.source,
    };

    const confirmMessage = `Adding new post:\n'${title}' ${authors? '\nby ' + authors : ''}`
    props.firebase.setNewPost(newPost, completionCallback(newPost));
  }

  const onCancel = event => {
    if (props.onFinish) props.onFinish();
    if (props.hasOwnProperty("toggleModal")) {
      props.toggleModal(false);
    }
  }

  const completionCallback = newPost => error => {
    if (error) {
      console.error(error)
      window.alert(`Oops! Failed to add new post '${newPost.title}' ◕︵◕`)
    } else {
      if (props.onFinish) props.onFinish();
      props.toggleModal(false);
    }
  }

  const onChangeCaption = event => {
    setCaption(event.target.value);
    setHashtags(getHashtags(event.target.value.toLowerCase()));
  }

  return (
    <div>
      <form onSubmit={onSubmit} className="mb-2">
        <div className="form-group row mx-1">
          <label htmlFor="inputTitle" className="col-form-label">Title</label>
          <div className="col-sm-10">
            <input
              className="w-100"
              name="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
              type="text"
              placeholder={isGuessing || !title? 'Autofilling...' : 'Title'}
              disabled={isGuessing}
              required={true}
              id="inputTitle"
            />
          </div>
        </div>
        <div className="form-group row mx-1">
          <label htmlFor="inputAuthors" className="col-form-label">By</label>
          <div className="col-sm-10">
            <input
            className="w-100"
              name="authors"
              value={authors}
              onChange={e => setAuthors(e.target.value)}
              type="text"
              placeholder={isGuessing || !authors? 'Autofilling...' : 'Artist or author'}
              disabled={isGuessing}
              id="inputAuthors"
            />
          </div>
        </div>
        <div className="form-group mx-1">
          <label htmlFor="captionTextarea">Caption</label>
          <textarea 
            value={caption}
            onChange={onChangeCaption}
            placeholder="Write a caption..."
            className="form-control" id="captionTextarea" rows="3">
          </textarea>
        </div>
        {image && 
        <div className="form-group mx-1">
          <label htmlFor="imageFileInput">Image</label>
          <input type="file" className="form-control-file" id="imageFileInput" />
          <img src={image} alt={title} />
        </div>
        }
        <div className="d-flex justify-content-between bd-highlight mb-3 mx-4">
          <div className="p-2 bd-highlight">
            <button className="btn btn-outline-danger btn-lg" onClick={onCancel}>Cancel</button>
          </div>
          <div className="p-2 bd-highlight">
            <button className="btn btn-secondary btn-lg" type="submit" disabled={isGuessing}> Post </button>
          </div>
        </div>
      </form>
      <PreviewAudio url={post.source} />
    </div>
  );
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  posts: state.lydsState.posts,
  currentLyd: state.lydsState.lyd,
});

const mapDispatchToProps = dispatch => ({
  onSetLyds: posts => dispatch({type: 'LYDS_SET', posts}),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withFirebase
)(SubmitSource);