import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';
import withLoadingSpinner from '../Loading';
import { useDebounce } from '../../hooks';
import { getYoutubeVideoId, sanitizeHtml } from '../../utils';
import { FiXCircle } from 'react-icons/fi';

const API_KEY = 'AIzaSyC5KLPopYAmz_kG5Yr31mEL8grO__AtrQo';

function AddYoutubeSourceUrl(props) {
  const [error, setError] = useState(null);
  const [sourceURL, setSourceURL] = useState('');
  const [result, setResult] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedURL = useDebounce(sourceURL, 500);

  useEffect(
    () => {
      if (debouncedURL && getYoutubeVideoId(debouncedURL)) {
        setIsSearching(true);
        getVideoFromYoutubeURL(debouncedURL)
          .then(result => {
            setResult(result.data.items[0]);
            setIsSearching(false);
          })
          .catch(handleRequestError);
      } else {
        setResult(null);
      }
    },
    [debouncedURL] // Only call effect if debounced search term changes
  );

  const handleRequestError = error => {
    setIsSearching(false);
    setError({message: "Oops! Something went wrong!", error});
  }
  
  const getVideoRequestURL = youtubeURL => {
    const videoId = getYoutubeVideoId(youtubeURL);
    return `https://www.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails&maxResults=4&id=${videoId}&key=${API_KEY}`;
  }

  const getVideoFromYoutubeURL = youtubeURL => {
    const requestURL = getVideoRequestURL(youtubeURL);
    return axios.get(requestURL) //.then(res => {console.log(res); return res})
  }

  const onAdd = result => event => {
    const videoURL = toYoutubeURL(result.id);
    const {title, thumbnails, description} = result.snippet;
    const thumbnail = thumbnails.standard? thumbnails.standard.url : thumbnails.default.url;
    props.onAddSource(videoURL, sanitizeHtml(title), thumbnail, description)
  }

  const VideoInfo = withLoadingSpinner(props => {
    if (result) {
      const {title, thumbnails, description} = result.snippet;
      return (
        <div>
          <hr/>
          <h3>{sanitizeHtml(title)}</h3>
          <div>
            <ReactPlayer
              className='react-player'
              width='100%'
              height='100%'
              url={debouncedURL}
              controls={true}
              onStart={() => console.log('onStart')}
            />
          </div>
          {description}
          <hr/>
          <div>
            <button 
              onClick={onAdd(result)}
              disabled={result === null}>Add
            </button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  })

  
  return (
    <div style={null && {padding: '15px' }}>
      <div>
        <input
          type="url"
          name="sourceURL"
          placeholder="Youtube URL"
          onChange={e => setSourceURL(e.target.value)}
        />
      </div>
      <VideoInfo loading={isSearching} loadingMessage='Searching video...' />
    </div>
  )
}

function SearchYoutube(props) {
  // State and setters for ...
  const [error, setError] = useState(null);
  const inputElement = useRef(null);
  // Search term
  const [searchTerm, setSearchTerm] = useState('');
  // API search results
  const [results, setResults] = useState([]);
  const [pageInfo, setPageInfo] = useState(null);
  const [pageToken, setPageToken] = useState(null);
  const [prevPageToken, setPrevPageToken] = useState(null);
  // Searching status (whether there is pending API request)
  const [isSearching, setIsSearching] = useState(false);
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms
  // As a result the API call should only fire once user stops typing
  const debouncedSearchTerm = useDebounce(searchTerm, 800);

  useEffect(
    () => {
      // console.log("SearchYoutube.useEffect...", debouncedSearchTerm, pageToken, prevPageToken)
      if (debouncedSearchTerm) {
        setIsSearching(true);
        getVideoSearchResults(debouncedSearchTerm, pageToken)
          .then(setVideoSearchResults)
          .catch(handleRequestError);
      } else {
        setResults([]);
        setPrevPageToken(null);
        setPageToken(null);
        setPageInfo(null);
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const handleRequestError = error => {
    console.log(error);
    setIsSearching(false);
    setError({message: "Dang! Something went wrong. For now, manually enter the URL."});
  }

  const getSearchVideosRequestURL = (searchTerms, pageToken) => {
    const keywords = encodeURI(searchTerms);
    const pageTokenParam = pageToken? `&pageToken=${pageToken}` : '';
    return `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=4${pageTokenParam}&q=${keywords}&type=video&videoEmbeddable=true&fields=items(id%2FvideoId%2Csnippet(description%2CpublishedAt%2Cthumbnails(default%2Cstandard)%2Ctitle))%2CregionCode%2CpageInfo%2CnextPageToken%2CprevPageToken&key=${API_KEY}`
  }

  const getVideoSearchResults = (searchTerm, pageToken) => {
    const requestURL = getSearchVideosRequestURL(searchTerm, pageToken);
    return axios.get(requestURL)//.then(res => {console.log(res); return res})
  }

  const onSelect = ({ id, snippet }) => event => {
    const { title, thumbnails, description } = snippet;
    const videoURL = toYoutubeURL(id.videoId);
    const videoTitle = sanitizeHtml(title);
    const thumbnail = thumbnails.standard && thumbnails.standard.url;
    // console.log('videoURL =', videoURL, 'videoTitle =', videoTitle, 'thumbnail =', thumbnail, 'description =', description, )
    props.onSelect(videoURL, videoTitle, thumbnail, description)
    onClearSearch(event);
  }

  const setVideoSearchResults = results => {
    setIsSearching(false);
    setResults(results.data.items);
    setPrevPageToken(results.data.prevPageToken);
    setPageToken(results.data.nextPageToken);
    setPageInfo(results.data.pageInfo);
  }

  const onNextPage = event => {
    // console.log("onNextPage!!")
    setIsSearching(true);
    getVideoSearchResults(debouncedSearchTerm, pageToken)
    .then(setVideoSearchResults)
    .catch(handleRequestError);
  }
  
  const onPrevPage = event => {
    // console.log("onPrevPage!!")
    setIsSearching(true);
    getVideoSearchResults(debouncedSearchTerm, prevPageToken)
      .then(setVideoSearchResults)
      .catch(handleRequestError);
  }
  const onClearSearch = event => {
    setIsSearching(false);
    setSearchTerm('');
    setResults([]);
    setPrevPageToken(null);
    setPageToken(null); 
    setPageInfo(null);
    inputElement.current.value = '';
  }

  if (error) {
    return (
      <div>
        <div className="text-danger">
          {error.message}
        </div>
      {props.urlInputOnError && <AddYoutubeSourceUrl onAddSource={props.onSelect} />}
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex flex-row bd-highlight">
        <div className="bd-highlight">
          <input
            className="form-control"
            ref={inputElement}
            name="searchYoutube"
            placeholder="Search"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="p-2 bd-highlight">
          {results.length > 0 && 
            <FiXCircle onClick={onClearSearch} className="bg-white text-dark" size="1.4em" />
          }
        </div>
      </div>
      {isSearching && <div>Searching ...</div>}
      {results.map(result => (
        <div
          key={result.id.videoId}
          style={{
            display: 'inline-block',
            width: '220px',
            margin: '10px'
          }}
        >
          <div className="h5 font-weight-light">{sanitizeHtml(result.snippet.title)}</div>
          <ReactPlayer
            className='react-player'
            width='100%'
            height='100%'
            url={toYoutubeURL(result.id.videoId)}
            controls={true}
            onStart={() => console.log('onStart')}
          />
          <button 
            type="button"
            className="btn btn-outline-primary w-100" 
            onClick={onSelect(result)}>
            Select</button>
        </div>
      ))}
      <div>
        <div>
          {pageInfo && <div className="m-2 font-weight-bold">{`Total videos: ${pageInfo.totalResults >= 100000? '>' : ''}${pageInfo.totalResults}`}</div>}
        </div>
        
        {prevPageToken && 
          <button type="button" 
            className="btn btn-sm btn-primary m-2" onClick={onPrevPage}>Back</button>
        }
        {pageToken && 
          <button type="button"
            className="btn btn-sm btn-primary m-2" onClick={onNextPage}>More...</button>
        }
      </div>
    </div>
  );
}

const toYoutubeURL = videoId => {
  return `https://www.youtube.com/watch?v=${videoId}`;
}

export default SearchYoutube;