import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorization = condition => Component => {
  function useAuthorization(props) {
    useEffect(() => {
      // Get observer's unsubscribe function
      const unsubscribe = props.firebase.auth.onAuthStateChanged(
        user => {
          if (!condition(user)) {
            props.history.push(ROUTES.SIGN_IN);
          }
        },
      );
      // Unsubscribe observer object
      return () => unsubscribe();
    }, []);

    return condition(props.authUser) ? (
      <Component {...props} />
    ) : null;
  }
  // TODO: Make sure were even need to connect 
  // the store's authUser state here.
  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
  });

  return compose(
    withRouter,
    withFirebase,
    connect(mapStateToProps),
  )(useAuthorization);
};

export default withAuthorization;