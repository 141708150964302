import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { SignUpLink } from '../SignUp';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

export function AccountStatusModal(props) {
  return (
    <div>
      <Modal isOpen={props.show} toggle={props.onShow} className="modal-dialog modal-lg">
        {props.headerTitle &&
        <ModalHeader toggle={props.onShow} className="text-center">
          <span className="text-capitalize">
            {props.headerTitle}
          </span>
        </ModalHeader>}
        <ModalBody className="mt-n3">
          {props.children}
        </ModalBody>
      </Modal>
    </div>
  );
}

const SignInPage = () => (
  <div className="card mx-4 mt-3">
    <SignInForm />
    <div className="text-center">
      <PasswordForgetLink />
      <SignUpLink />
    </div>
  </div>
);

const INITIAL_FIELDS_STATE = {
  email: '',
  password: '',
  error: null,
};

function SignInFormBase(props){
  const { firebase, history } = props;
  const [fields, setFields] = useState({ ...INITIAL_FIELDS_STATE });
  const [isVerified, setIsVerified] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    email,
    password,
    error,
  } = fields;

  const onShowModal = () => {
    setShowModal(!showModal);
  }

  const signInCallback = async userCred => {
    if (!userCred.user) throw {code: 'AUTHENTICATION_ERROR', message: 'Account login unavailable at this time'}
    const verified = userCred.user.emailVerified;
    const activated = await firebase.db.ref(`users/${userCred.user.uid}/activated`)
                              .once('value').then(ss => ss.val());
    setIsActivated(activated);
    setIsVerified(verified);
    if (!activated) {
      setShowModal(true);
      firebase.doSignOut();
      return;
    }
    
    if (!verified) {
      setShowModal(true);
      return;
    }

    setFields({ ...INITIAL_FIELDS_STATE });
    history.push(ROUTES.LANDING);
  }
  
  const onSubmit = event => {
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(signInCallback)
      .catch(error => {
        setFields( { ...fields, error });
      });

    event.preventDefault();
  };

  const onChange = event => {
    const newState = {
      ...fields, 
      [event.target.type]: event.target.value 
    };

    setFields(newState);
  };

  const onClickResend = event => {
    firebase.doSendEmailVerification()
      .then(() => {
        setVerificationSent(true);
        firebase.doSignOut();
      });
    
    event.preventDefault();
  }
  // TODO: Factor this code out because it's repeated in Session/withEmailVerification
  const modalBody = () => {
    if (!isActivated) {
      return (
        <div className="card-body mt-0">
          <h1 className="text-center display-5 my-3 font-weight-normal text-light bg-primary">︴Lyddy ︴</h1>
          <p className="lead ">Thanks for signing up!</p>
          <p>We're in the process of activating other accounts. Once your account has been activated, you'll receive a verification email.</p>
          <p>In the meantime, check your inbox.</p>
          <small className="text-muted">*Don't forget to also check your spam or junk inbox.</small>
        </div>
      );
    } else {
      return (
        <div className="card-body mt-0">
          <h1 className="text-center text-light display-5 my-3 font-weight-normal bg-primary">︴Lyddy ︴</h1>
          <p className="lead">Email Verification Sent</p>
          <p>A verification message was sent to your email</p>
          <p className="font-weight-bold">{email}</p>
          <p>If you didn't find it, click to resend the verification message.</p>
          <div className="text-center">
            <button onClick={onClickResend} disabled={verificationSent} className="btn btn-secondary">{verificationSent? 'Verification Sent!' : 'Resend Verification'}</button>
          </div>
          <small className="text-muted">*Don't forget to also check your spam or junk inbox.</small>
        </div>
      );
    }
  }

    return (
      <div>
        <form className="form-signin" onSubmit={onSubmit}>
          <div className="text-center mb-4">
            <h1 className="display-5 my-3 font-weight-normal bg-primary text-light">︴Lyddy ︴</h1>
            <h1 className="lead px-5">Log in to hear sounds and music from your friends.</h1>
          </div>

          <div className="form-label-group">
            <input type="email" id="inputEmail" onChange={onChange} className="form-control bg-white" placeholder="Email address" required autoFocus />
            <label htmlFor="inputEmail">Email address</label>
          </div>

          <div className="form-label-group">
            <input type="password" id="inputPassword" onChange={onChange} className="form-control" placeholder="Password" required />
            <label htmlFor="inputPassword">Password</label>
          </div>
          <button className="btn btn-lg btn-secondary btn-block" type="submit">Log in</button>
        </form>
        {error && <div className="text-center text-danger">{error.message}</div>}
        <AccountStatusModal show={showModal} onShow={onShowModal}>
          {modalBody()}
        </AccountStatusModal>
      </div>
    );
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };