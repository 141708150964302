import ReactPlayer from 'react-player';
import axios from 'axios';
import moment from 'moment';

export const RESERVED_USERNAMES = [
  'account',
  'about',
  'accounts',
  'admin',
  'lyddy',
  'explore',
  'home',
  'p'
];
export const USERNAME_RE_PATTERN = /^(?![.].*)(?!.*[.]{2,}.*)(?!.*_{5,}.*)(?!.*[.]$)[a-z0-9_.]{1,30}$/;
export const EMAIL_RE_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // https://stackoverflow.com/a/46181
export const URL_RE_PATTERN = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
export const YOUTUBE_URL_PATTERN = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
export const MATCH_HOSTNAME_PATTERN = /^https?\:\/\/([^\/:?#]+)(?:[\/:?#]|$)/;
export function validEmailFormat(email) {
  return EMAIL_RE_PATTERN.test(String(email).toLowerCase());
}

export function isValidUsername(username) {
  return (
    !RESERVED_USERNAMES.includes(username) &&
    USERNAME_RE_PATTERN.test(username)
  );
}

export function getSourceType(url) {
  if (YOUTUBE_URL_PATTERN.test(url)) return 'youtube';

  const matches = url.match(MATCH_HOSTNAME_PATTERN);
  if (!matches) return null;
  
  const hostname = matches[1].toLowerCase();
  if (hostname.includes('mixcloud')) return 'mixcloud';
  if (hostname.includes('vimeo')) return 'vimeo';
  if (hostname.includes('bandcamp')) return 'bandcamp';
  if (hostname.includes('soundcloud')) return 'soundcloud';

  return 'file';
}

export function getYoutubeVideoId(url) {
  // https://stackoverflow.com/a/8260383
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  var match = url.match(regExp);
  return (match && match[7].length==11)? match[7] : false;
}

export function getHashtags(searchText) {
  const regexp = /\B\#\w\w+\b/g
  const hashtags = searchText.match(regexp);
  if (hashtags) {
      return hashtags.map(tag => tag.replace('#', ''));
  } else {
      return [];
  }
}

export function parsedDaysAgo(dateLiked) {
  const now = moment();
  let diff = now.diff(dateLiked, "days");
  let parsed = diff? `${diff} day${diff === 1? '' : 's'} ago` : 'Today';
  if ((diff > 30) && (diff < 352)) {
    diff = now.diff(dateLiked, "months");
    parsed = `${diff} month${diff === 1? '' : 's'} ago`;
  } 

  if (diff > 352) {
    diff = now.diff(dateLiked, "years");
    parsed = `${diff} year${diff === 1? '' : 's'} ago`;
  }
  
  return parsed;
}

export function getSortedPosts(posts) {
  const postValues = [...posts];
  const sortedValues = postValues.sort((a,b) => b.dateAdded - a.dateAdded);
  return sortedValues;
}

export function canPlay(url) {
  return ReactPlayer.canPlay(url);
}

export function validSource(url) {
  var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);

  if (url.match(regex)) {
    console.log("Successful match", url.match(regex), url);
  } else {
    console.log("No match", url.match(regex), url);
  }
  return !!url.match(regex);
}

export function getRandomColor(min, max, hex=false) {
  const randVal = Math.floor(Math.random() * (max - min + 1)) + min;
  const pattern = [randVal, min, min, randVal, max, max];
  const idx = Math.floor(Math.random() * 6);
  const [r, g, b] = [
    pattern[(idx + 0) % 6], 
    pattern[(idx + 2) % 6], 
    pattern[(idx + 4) % 6]
  ];

  if (hex) {
    return toHex([r, g, b]);
  } else {
    return [r, g, b];
  }
}

export function toHex(rgb){ 
  const hexes = rgb.map(c => c.toString(16).padStart(2, '0'));
  return hexes.join('');
}

function _getPlaceholderPhotoURL(username) {
  const chars = username.slice(0,2);
  const size = 150;
  const fontSize = Math.floor(size * 0.65);
  const randRgb = getRandomColor(120, 230);
  const randColor = toHex(randRgb);
  const complColor = toHex(randRgb.map(c => Math.abs(c - 255)));
  const url = `https://dummyimage.com/${size}x${size}/${randColor}/${complColor}.png&text=${chars}`;
  // const params = `?l=${chars}&f=${complColor}&b=${randColor}&s=${fontSize}`;
  // const url = `https://ipsumimage.appspot.com/${size}x${size}.png` + params;
  return url;
}

export function getPlaceholderPhotoURL(name) {
  let photoURL = 'https://dummyimage.com/150x150/ff6f4f/f7f7f7.png&text=%E2%80%A2_%E2%80%A2'
  try {
    photoURL = _getPlaceholderPhotoURL(name)
  }
  catch(err) {
  } 
  return photoURL
}

export function sanitizeHtml(str) {
  return str.replace(/&quot;/g, '\"').replace('&#39;', '\'')
}

export function writtenDate(datetime) {
  const formattedDate = (datetime ? 
                      `Added ${moment(datetime).fromNow()}` 
                      : '');
  return formattedDate;
}

// *** APIs
export function guessedTitleArtist(fullTitle) {
  const sanitizedTitle = sanitizeHtml(fullTitle);

  const includedNames = fullName => {
    const ignored = ['the', 'of', 'a', '-'];
    const nameElements = fullName.split(' ').map(n => n.toLowerCase());
    const titleElements = sanitizedTitle.split(' ').map(n => n.toLowerCase());
    const a = new Set(titleElements);
    const b = new Set(nameElements);
    const intersection = new Set([...a].filter(name => !ignored.includes(name) && b.has(name)));
    
    return intersection;
  }

  const query = `recording/?query=recording:${encodeURI(sanitizedTitle)}`;
  return axios.get(`https://musicbrainz.org/ws/2/${query}&fmt=json`)
    .then(result => {
      let title = sanitizedTitle;
      let artist = null;
      let score = 70;
      // console.log(result.data.recordings)
      result.data.recordings.forEach(rec => {
        const artistName = rec['artist-credit'][0]['artist']['name'];
        const matches = includedNames(artistName);
        if (!!matches.size){
          if (rec.score > score) {
            artist = artistName;
            title = rec.title;
            score = rec.score;
          }
        } else {
          return;
        }
      })
      if ((artist && !includedNames(artist)) || !artist) {
        const splitTitle = fullTitle.split('-');
        artist = splitTitle[0].trim();
        title = splitTitle[splitTitle.length - 1].trim();
      }
      return [title, artist];
    })
}

/** Source (commit 41d11f2): https://github.com/trekhleb/javascript-algorithms/blob/master/src/algorithms/string/levenshtein-distance/levenshteinDistance.js
 * @param {string} a
 * @param {string} b
 * @return {number}
 */
export function levenshteinDistance(a, b) {
  // Create empty edit distance matrix for all possible modifications of
  // substrings of a to substrings of b.
  const distanceMatrix = Array(b.length + 1).fill(null).map(() => Array(a.length + 1).fill(null));

  // Fill the first row of the matrix.
  // If this is first row then we're transforming empty string to a.
  // In this case the number of transformations equals to size of a substring.
  for (let i = 0; i <= a.length; i += 1) {
    distanceMatrix[0][i] = i;
  }

  // Fill the first column of the matrix.
  // If this is first column then we're transforming empty string to b.
  // In this case the number of transformations equals to size of b substring.
  for (let j = 0; j <= b.length; j += 1) {
    distanceMatrix[j][0] = j;
  }

  for (let j = 1; j <= b.length; j += 1) {
    for (let i = 1; i <= a.length; i += 1) {
      const indicator = a[i - 1] === b[j - 1] ? 0 : 1;
      distanceMatrix[j][i] = Math.min(
        distanceMatrix[j][i - 1] + 1, // deletion
        distanceMatrix[j - 1][i] + 1, // insertion
        distanceMatrix[j - 1][i - 1] + indicator, // substitution
      );
    }
  }

  return distanceMatrix[b.length][a.length];
}