import React, { useState, useEffect, useReducer } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { MdHearing } from 'react-icons/md';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { LydList } from '.';
import { usePrevious } from '../../hooks'
import { getSortedPosts } from '../../utils';
import SuggestedFollows from '../Social/SuggestedFollows';
import _ from 'lodash';

function reducer(state, action) {
  var filtered;
  switch (action.type) {
    case 'ADD_PROFILES':
      // console.log(state, action.profiles)
      return { ...state, profiles: { ...state.profiles, ...action.profiles } };
    case 'SET_POSTS':
      return { ...state, posts: { ...action.payload } };
    case 'ADD_POSTS':
      // console.log({posts: { ...state.posts, ...action.payload }})
      return { ...state, posts: { ...state.posts, ...action.payload } };
    case 'REMOVE_POST':
      filtered = _.pickBy(state.posts, 
        val =>  !action.postId.includes(val.lydId) );
      return { ...state, posts: { ...filtered } };
    case 'REMOVE_USER_POSTS':
      filtered = _.pickBy(state.posts, 
        val =>  !action.uids.includes(val.userId) );
      return { ...state, posts: { ...filtered } };
    default:
      throw new Error("oops. something broke!");
  }
}

function LydsFeed(props) {
  const { firebase, onSetLyds, onRemoveLyd, onUpdateLyds, onSetStream, match} = props;
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(reducer, {posts: {}, profiles: {}});
  const [userUids, setUserUids] = useState([]);
  const [numPosts, setNumPosts] = useState(null);
  const prevUserUids = usePrevious(userUids);
  const [skipAddPosts, setSkipAddPosts] = useState(false);
  const streamType = Object.keys(match.params)[0];
  let streamName = match.params[streamType] || '';
  
  const getDiff = (a,b) => {
    const A = a? [...a] : [];
    const B = b? [...b] : [];
    if (B.length > A.length) {
      return [true, B.filter(x => !A.includes(x))]
    } else {
      return [false, A.filter(x => !B.includes(x))]
    }
  }

  const followingCallback = ss => {
    const uids = Object.keys(ss.val() || {});
    uids.push(props.authUser.uid);
    firebase.getNumPosts(uids).then(setNumPosts);
    setUserUids(uids);
  }
  
  useEffect(() => {
    // console.log("useEffect 1... ")
    let listener = null;
    setLoading(true);
    if (!streamType) {
      listener = firebase.subscribeFollowing(followingCallback);
    } else if (streamType === 'username') {
      streamName = `@${streamName}`;
      firebase.getUidFromUsername(match.params[streamType])
        .then(uid => {
          setUserUids([uid])
          firebase.getNumPosts([uid]).then(setNumPosts);
        })
    } else if (streamType === 'hashtag') { // TODO: Separate this out and make it a listener. 
      streamName = `#${streamName}`;
      firebase.getPostsFromHashtags([match.params[streamType]])
      .then(results => {
        const posts = Object.values(results);
        const uids = [];
        posts.forEach(post => {
          const uid = post.userId;
          if (!uids.includes(uid)) uids.push(uid);
        })
        setNumPosts(posts.length);
        setSkipAddPosts(true);
        setUserUids(uids);
        dispatch({ type: 'SET_POSTS', payload: results });
      });
    }

    document.title = 'Lyddy ' + (streamName || ' Stream');
    onSetStream(streamName);

    return () => { 
      if (listener) listener.off('value', followingCallback); 
    }

  }, [streamName])

  const addPostsCallback = (ss, key) => {
    const post = ss.val();
    if (!skipAddPosts && post){
      dispatch({ type: 'ADD_POSTS', payload: post });
      onUpdateLyds(Object.values(post))
    }
  }

  const removePostsCallback = ss => {
    const ssValue = ss.val();
    if (ssValue) {
      dispatch({ type: 'REMOVE_POST', postId: ssValue.lydId })
      onRemoveLyd(ssValue.lydId);
    }
  }

  const profilesCallback = ss => {
    const profile = ss.val();
    if (!profile) return;
    const uid = profile.userId;
    dispatch({ 
      type: 'ADD_PROFILES', 
      profiles: { [uid]: profile } }
    );
  }

  useEffect(() => {
    // console.log("useEffect 2...")
    const [shouldAdd, uids] = getDiff(prevUserUids, userUids);

    uids.forEach((uid, idx) => {
      const usersRef = firebase.db.ref(`users/${uid}`);
      const postsRef = firebase.db.ref(`posts/${uid}`);
      
      if (shouldAdd) {
        // console.log("SUBSCRIBING POSTS BY!÷!!...", uid)
        usersRef.on('value', profilesCallback);
        postsRef.on('value', addPostsCallback);
        postsRef.on('child_removed', removePostsCallback);
      } else {
        // console.log("UNSUBSCRIBING!!!", uid)
        usersRef.off('value', profilesCallback);
        postsRef.off('value', addPostsCallback);
        postsRef.off('child_removed');
      }
    });
    
    if (!shouldAdd) {
      dispatch({type: 'REMOVE_USER_POSTS', uids})
    }
  }, [numPosts, userUids])
  
  useEffect(() => {
    const isComplete = Object.keys(state.posts).length === numPosts;
    if (isComplete) {
      const sortedPosts = getSortedPosts(Object.values(state.posts));
      setLoading(false);
      onSetLyds(sortedPosts);
    }
  }, [state.posts])
  
  // TODO: This is breaking when deleting a post from the home feed.
  // useEffect(() => { 
  //   // console.log("LydsFeed.useEffect_2   props.posts = ", props.posts.length, props.posts )
  //   if (props.posts) {
  //     dispatch({ type: 'SET_POSTS', payload: props.posts })
  //   }
  // }, [props.posts])
  
  // onSetLyds(Object.values(state.posts));
  const sortedPosts = getSortedPosts(Object.values(state.posts));
  const doRenderMessage = !streamType && !sortedPosts.length && !loading;
  return (
    <div>
      {props.children}
      <div className="container">
        {doRenderMessage &&
        <div className="mt-2 mb-4 text-center">
          <MdHearing size="3em" />
          <div className="h4 mt-2">Start Listening by Following People</div>
        </div> 
        }
        {!streamType && <SuggestedFollows />}
        <LydList 
          loading={loading}
          loadingMessage={"Loading stream..."}
          posts={sortedPosts}
          profiles={state.profiles}
          currentLyd={props.currentLyd}
          isPlaying={props.isPlaying}
          emptyMessage={!streamType? null : "No Posts Yet" }
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
    posts: state.lydsState.posts,
    currentLyd: state.lydsState.lyd,
    isPlaying: state.lydsState.playing
});

const mapDispatchToProps = dispatch => ({
  onSetLyds: posts => dispatch({type: 'LYDS_SET', posts}),
  onRemoveLyd: lydId => dispatch({type: 'LYD_REMOVE', lydId}),
  onUpdateLyds: posts => dispatch({type: 'LYDS_UPDATE', posts}),
  onSetStream: stream => dispatch({type: 'SET_STREAM', stream}),
});

export default compose(
  withRouter,
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )
)(LydsFeed);