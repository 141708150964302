import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ProfilesList } from '../Profile';

function MutualFollowersList({ socialItems }) {
  // const followerNames =["lalal", "scout_berry", "anniegallos", "openstudiosvan", "someUser1", "someUser2"] // user.followers
  if (socialItems.length === 0) {
    return null
  }
  const followerNames = socialItems.map(item => item.alias_name || item.username)
  // console.log(followerNames)
  const MAX = (followerNames.length < 3)? followerNames.length : 3
  // console.log(followerNames.slice(0, MAX))
  const mutualFollowers = followerNames.slice(0, MAX).map((follower, idx) => {
    var followerName
    if (idx < (MAX - 1)) {
      followerName = <span><b>{follower}</b>, </span>
    } else if (idx === (MAX - 1)) {
      followerName = <span><b>{follower}</b></span>
    }
    return (<span key={idx}>{followerName}</span>)
    })

  const moreFollowersNum = (followerNames.length > MAX)? `+ ${followerNames.length - MAX} more` : null  
  // console.log(mutualFollowers)
  // console.log(moreFollowersNum)
  return <small className="text-info text-decoration-none">Followed by {mutualFollowers} {moreFollowersNum}</small>
}

function SocialNetworksList(props) {
  const [socialItems, setSocialItems] = useState([]);

  useEffect(() => {
      props.firebase
        .getMutualConnectionProfiles(props.userId, props.network, props.mutualOnly)
        .then(setSocialItems);
  }, [props.userId])

  return (
    <div>
      <ProfilesList profiles={socialItems} authUid={props.authUserId} isPending={false}/>
    </div>
  )
}

const SocialNetworks = withFirebase(SocialNetworksList);

function SocialConnectionsCard(props) {
  return (
    <div className="card" style="width: 18rem;">
      <div className="card-header">
        {props.network}
      </div>
        <SocialNetworks 
          network={props.network}
          mutualOnly={props.mutualOnly}
          authUserId={props.authUserId}
          userId={props.userId}/>
    </div>
  );
}

function SocialConnectionsModal(props) {
  return (
    <div>
      <Modal isOpen={props.show} toggle={props.onShow} className="modal-dialog modal-lg">
        <ModalHeader toggle={props.onShow} className="text-center">
          <span className="text-capitalize">
          {props.network}
          </span>
        </ModalHeader>
        <ModalBody>
          <SocialNetworks 
            network={props.network} 
            mutualOnly={props.mutualOnly}
            authUserId={props.authUserId} 
            userId={props.userId}/>
        </ModalBody>
      </Modal>
    </div>
  );
}

export { MutualFollowersList, SocialNetworksList, SocialConnectionsCard };
export default SocialConnectionsModal;


