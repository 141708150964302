import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';

const withAuthentication = Component => {
  // Using hook
  function WithAuthentication(props) {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
      // Get observer's unsubscribe function
      const unsubscribe = props.firebase.auth.onAuthStateChanged(
        user => {
          setCurrentUser(user);
          props.onSetAuthUser(user); 
        }
      );
      
      let settingsRef = null;
      let onValueChange = null;
      if (currentUser) {
        settingsRef = props.firebase.db.ref(`userSettings/${currentUser.uid}`)
        onValueChange = settingsRef.on('value', snapshot => {
          if (snapshot) {
            const settings = snapshot.val();
            props.onUpdateAuthUser({ settings });
          }
        });
      }
      // Unsubscribe observer object
      return () => {
        unsubscribe();
        if (onValueChange) {
          settingsRef.off('value', onValueChange)
        }
      };
    }, [currentUser]);

    return <Component {...props} />;
  }

  const mapDispatchToProps = dispatch => ({
    onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
    onUpdateAuthUser: data => dispatch({ type: 'AUTH_USER_UPDATE', data }),
  });

  return compose(
    withFirebase,
    connect(
      null,
      mapDispatchToProps,
    ),
  )(WithAuthentication);
};

export default withAuthentication;