import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { MutualFollowersList, SocialButton } from '../Social';

const ActionButtons = withFirebase(({ authUid, profile, isPending, firebase, onFollowCb }) => {
  const [pending, setPending] = useState(isPending);
  const [loading, setLoading] = useState(false);
  const uid = profile.userId;
  if (authUid === uid) return null;
  
  const onDecline = event => {
    event.preventDefault();
    setLoading(true);
    firebase.rejectFollowRequest(uid)
      .then(() => setLoading(false));
    setPending(false);
  }
  
  const onConfirm = event => {
    event.preventDefault();
    setLoading(true);
    firebase.acceptFollowRequest(uid)
      .then(() => setLoading(false));
    setPending(false);
  }

  if (pending) {
    if (loading) {
      return (
        <button className="btn btn-outline-primary btn-sm btn-block text-capitalize">
          <div className="spinner-border spinner-border-sm" role="status"></div>
        </button>
      );
    }
    return (
      <div>
        <button 
          className="btn btn-secondary text-capitalize mx-2"  
          onClick={onConfirm}
        >
          Approve
        </button>
        <button 
          className="btn btn-outline-primary text-capitalize"  
          onClick={onDecline}
        >
          Decline
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <SocialButton uid={uid} className="btn" onFollowCb={onFollowCb} />
      </div>
    );
  }
})

function ProfilesList(props) {
  return (
    <ul style={{overflow: "auto", maxHeight:"300px"}} className="list-group list-group-flush borderless">
      {props.profiles.map(profile => (
        <li key={profile.userId} 
          className={`list-group-item ${props.bgColor? props.bgColor : ""}`}>
          <div className="d-flex bd-highlight mx-n3">
            <div className="bd-highlight align-self-center">
              <Link to={`/${profile.alias_name || profile.username}`}>
                <img className="rounded-circle alias-thumbnail" src={profile.alias_image || profile.photoURL} />
              </Link>
            </div>
            <div className="bd-highlight align-self-center mr-auto">
              <div className="d-flex flex-column bd-highlight ml-2">
                <div className="bd-highlight"><Link to={`/${profile.alias_name || profile.username}`} className="nounderline text-dark">{profile.alias_name || profile.username}</Link></div>
                {(profile.full_name || profile.fullname) && <div className="bd-highlight mt-n2"><small className="text-muted">{profile.full_name || profile.fullname}</small></div>}
                {profile.mutualFollowers && !!profile.mutualFollowers.length && <div className="bd-highlight mt-n2"><MutualFollowersList socialItems={profile.mutualFollowers}/></div>}
              </div>
            </div>
            <div className="bd-highlight align-self-center mr-1">
              <ActionButtons 
                profile={profile} 
                authUid={props.authUid} 
                isPending={props.isPending}
                onFollowCb={props.onFollowCb}
              />
            </div>
          </div>

        </li>)
      )}
    </ul>
  );
}

export default ProfilesList;
export { ActionButtons };