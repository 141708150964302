import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { ClipboardReadPrompt } from '../PostLyd';
import { NavProfileLink,
  NavSearchButton,
  NavHomeLink,
  NavAddButton,
  NavNotificationsButton } from '../Navigation';
import { withAuthUser } from '../Session';

function NavMenu(props) {
  if (!props.authUser) return null;

  return (
    <div style={{borderWidth: "1px", borderStyle: "solid hidden hidden hidden"}} className="bg-white">
      <nav className="nav nav-pills d-flex justify-content-around">
        <NavHomeLink className="nav-link rounded-0 align-self-center ml-1"/>
        {/* <NavSearchButton className="nav-link rounded-0 align-self-center mx-n2" /> */}
        <NavAddButton className="nav-link" /> 
        {/* <NavNotificationsButton className="nav-link rounded-0 align-self-center mx-n2" /> */}
        <NavProfileLink className="nav-link rounded-0 align-self-center" username={props.authUser.displayName} />
      </nav>
    </div>
  );
}

const MainMenu = compose(
  withRouter,
  withAuthUser
)(NavMenu)

function Footer(props) {
  return (
    <div>
      <ClipboardReadPrompt />
      <MainMenu />
    </div>
  );
}

export default Footer;