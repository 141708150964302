import app from 'firebase/app';
import 'firebase/auth'
import 'firebase/database';

import _ from 'lodash';
import moment from 'moment';

import { getPlaceholderPhotoURL, getSortedPosts } from '../../utils'
import * as SOCIAL from '../../constants/social';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {

  constructor() {
      app.initializeApp(config);
      this.auth = app.auth();
      this.db = app.database();
    }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
      this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: 'https://lyddy.stream',
    });

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
      this.auth.currentUser.updatePassword(password);

  // *******************************************************************
  // *                            User API                             *
  // *******************************************************************
  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  createNewUserAccount(email, password, username, fullname) {
    const newUsername = username.toLowerCase().trim()
    const photoURL = getPlaceholderPhotoURL(newUsername)

    const profile = {
      fullname,
      photoURL, 
      username: newUsername,
      bio: '',
      website: '',
      public: true,
      dateCreated: moment().valueOf(),
      activated: false
    };

    const doCreate = this.auth.createUserWithEmailAndPassword(email, password);
    return doCreate.then(userCredential => {
      return this.updateUsername(newUsername)
              .then(() => {
                const newUser = userCredential.user;
                const uid = newUser.uid;
                const sets = [
                  newUser.updateProfile({displayName: newUsername, photoURL}),
                  this.db.ref(`users/${uid}`).set({ ...profile, userId: uid }),
                ];
                return Promise.all(sets).then(() => profile);
      });
    });
  }
  
  updateUsername(username) {
    const newUsername = username.toLowerCase().trim();
    return this.usernameExists(newUsername)
            .then(exists => {
              if (exists) {
                throw {
                  code: 'UPDATE_USERNAME_ERROR', 
                  message: `Username '${username}' is taken`
                }
              } else {
                const uid = this.auth.currentUser.uid;
                const usernamesRef = this.db.ref(`usernames/${uid}`);
                return usernamesRef.set(newUsername)
              }
            })
  }

  async doChangeUsername(username) {
    const newUsername = username.toLowerCase().trim();
    const photoURL = getPlaceholderPhotoURL(newUsername);
    const uid = this.auth.currentUser.uid
    return this.updateUsername(newUsername)
            .then(() => {
              return Promise.all([
                this.db.ref(`users/${uid}`)
                  .update({
                    username: newUsername,
                    alias_image: photoURL,
                    photoURL
                  }),
                this.auth.currentUser
                  .updateProfile({
                    displayName: newUsername,
                    photoURL
                  })
              ]);
            })
  }

  getUsernameFromUid(uid) {     
    const usernamesRef = this.db.ref(`usernames/${uid}`);
    return usernamesRef.once('value')
            .then(snapshot => {
              return snapshot.val();
            });
  }

  getUsernames(uids) {    
    const promises = uids.map(uid => 
      this.db.ref(`usernames/${uid}`).once('value')
    );

    return Promise.all(promises).then(snapshots => {
            const usernames = {};
            snapshots.map(snapshot => {
              usernames[snapshot.key] = snapshot.val();
            });
            return usernames;
    });
  }

  getUidFromUsername(name) { 
    const usernamesQuery = this.db.ref('usernames')
                            .orderByValue()
                            .equalTo(name);

    return usernamesQuery.once('value')
            .then(snapshot => {
              const uidUsername = snapshot.val();
              if (uidUsername) {
                const uid = Object.keys(uidUsername)[0];
                return uid;
              } else {
                throw {code: 'USER_PROFILE_ERROR', message: 'user doesn\'t exist'};
              }
            });
  }

  getUidsFromUser(name) {
    const usernamesQuery = this.db.ref('usernames')
                            .orderByValue()
                            .equalTo(name);

    return usernamesQuery.once('value')
            .then(snapshot => {
              const uidUsername = snapshot.val();
              if (uidUsername) {
                return Object.keys(uidUsername)[0]
              }
              return null;
            });
  }
  
  async getPostsAndProfiles(username) {
    let uid = this.auth.currentUser && this.auth.currentUser.uid;
    let feedProfiles = {};
    const authUsername = this.auth.currentUser && this.auth.currentUser.displayName;
    if (username) {
      if (username !== authUsername) {
        uid = await this.getUidFromUsername(username);
      }
    } else {
      // feedProfiles = await this.getPublicProfiles();
      feedProfiles = await this.getFollowingProfiles(this.auth.currentUser.displayName)
      // console.log(feedProfiles)
    }
    const profile = await this.profilesRefOnce([uid]);
    const profiles = {...profile, ...feedProfiles};
    const uids = Object.keys(profiles);
    const posts = await this.postsRefOnce(uids);

    const mergedPosts = posts.map(post => (
      {...post, user: profiles[post.userId]}
    ));

    return mergedPosts;
  }

  subscribeFollowing(callback) {
    if (!this.auth.currentUser) throw new Error('user must be logged in');
    const uid = this.auth.currentUser.uid;
    const dbPath = `user_network/${uid}/following`;
    this.db.ref(dbPath).on('value', callback);

    return this.db.ref(dbPath);
  }

  async getFollowingProfiles(username) {
    const uid = await this.getUidFromUsername(username);
    const conns = await this.getSocialNetwork(uid, 'following');
    return this.profilesRefOnce(Object.keys(conns));
  }

  getActivatedProfiles() {
    const usersQuery = this.db.ref('users')
                        .orderByChild('activated')
                        .equalTo(true);
    return usersQuery.once('value').then(snapshot => snapshot.val())
  }

  getPublicProfiles() {
    const usersQuery = this.db.ref('users')
                        .orderByChild('public')
                        .equalTo(true);

    return usersQuery.once('value').then(snapshot => snapshot.val())
  }

  async usernameExists(name) {
    try {
      const uid = await this.getUidFromUsername(name);
      return !!uid
    } catch(error) {
      return false;
    }
  }

  profilesRefOnce(uids, handler) {
    const promises = uids.map(
      uid => this.db.ref(`users/${uid}`).once('value')
    );

    return Promise.all(promises)
            .then(this.usersRefCallback(handler));
  }

  usersRefCallback(handler) {
    return snapshots => {
      const userProfiles = {};
      snapshots.forEach(snapshot => {
        const profile = snapshot.val();
        if (profile) {
          userProfiles[snapshot.key] = profile;
        }
      })
      // console.log(userProfiles)
      if (handler) {
        handler(userProfiles);
      }
      return userProfiles;
    }
  }

  getUserProfile(uid) {
    return this.db.ref(`users/${uid}`)
            .once('value').then(ss => ss.val());
  }

  async getUserProfiles(uids) {
    const promises = uids.map(uid => this.getUserProfile(uid));
    const userProfiles = await Promise.all(promises);
    return userProfiles;
  }
  getProfilePromise(uid) {
    const dbPaths = [
      `users/${uid}`,
      `posts/${uid}`,
      `user_network/${uid}/following`,
      `user_network/${uid}/followers`,
    ];
    const promises = dbPaths.map(p => this.db.ref(p).once('value')); //TODO: Turn this into "on" for dynamic updating OR add listener.
    return Promise.all(promises).then(data => {
      const values = data.map(snap => snap.val()) 
      let [
        user, 
        posts, 
        following, 
        followers
      ] = values
      following = following || []
      followers = followers || []
      posts = posts || []

      const followingTotal = Object.keys(following).length
      const followersTotal = Object.keys(followers).length
      const postsTotal = Object.keys(posts).length
      
      const profileData  = {
        ...user, 
        postsTotal, 
        followingTotal, 
        followersTotal
      };

      return profileData
    });
  }

  getProfileFromUsername(name) {
    return this.getUidFromUsername(name)
      .then(uid => {
        return this.getProfilePromise(uid);
      })
      .then(profileData => {
        let profile = {};
        if (profileData) {
          profile = {
            uid: profileData.userId,
            username: profileData.username,
            photoURL: profileData.alias_image,
            fullname: profileData.full_name,
            bio: profileData.bio,
            website: profileData.website,
            postsTotal: profileData.posts_total,
            followersTotal: profileData.followers_total,
            followingTotal: profileData.following_total,
            public: profileData.public,
          }
        } 
        return { ...profile, ...profileData }; // TODO: Remove the spread operator on profile because we won't need it once getProfilePromise is committed
      })
  }

  async getSettings(param) {
    if (!this.auth.currentUser) throw new Error('User must be logged in');
    const dbPath = `/userSettings/${this.auth.currentUser.uid}/${param}`;
    const value = await this.db.ref(dbPath).once('value')
                          .then(ss => ss.val());
    return value
  }

  setSettings(param, value) {
    if (!this.auth.currentUser) throw new Error('user must be logged in');
    const dbPath = `/userSettings/${this.auth.currentUser.uid}/${param}`;
    return this.db.ref(dbPath).set(value);
  }

  getUserPostIds(uid) {
    const postsRef = this.db.ref(`/posts/${uid}`);
    return postsRef.once('value') 
            .then(snapshot => (snapshot.val()? 
                                Object.keys(snapshot.val()) 
                                : []))
  }

  async setProfileProp(prop, value, onComplete) {
    const uid = this.auth.currentUser.uid;
    const ref = this.db.ref(`users/${uid}/${prop}`);
    await ref.set(value, onComplete);
  }

  async setProfilePublic(isPublic) {
    const uid = this.auth.currentUser.uid;
    const userPublicRef = this.db.ref(`/users/${uid}/public`);
    await userPublicRef.set(isPublic);
    if (isPublic) await this.respondAllFollowRequests(true);
  }
  
  getProfilePublic() {
    const dbPath = `/users/${this.auth.currentUser.uid}/public`;
    const userPublicRef = this.db.ref(dbPath);
    return userPublicRef.once('value')
            .then(snapshot => snapshot.val());
  }

  // *******************************************************************
  // *                            Posts API                            *
  // *******************************************************************
  postsRefOnce(uids = [], handler) {
    const promises = uids.map(
      uid => this.db.ref(`posts/${uid}`).once('value')
    );

    return Promise.all(promises)
            .then(this.postsRefCallback(handler));
  }

  postsRefOff() {
    this.db.ref('posts').off('value');
  }

  postsRefOn(uids = [], handler) {
    let postsQuery = this.db.ref('posts').orderByKey();
    if (uids.length > 1) {
      uids.sort();
      postsQuery = postsQuery
                    .startAt(_.first(uids))
                    .endAt(_.last(uids));
    }
    postsQuery.ref.on('value', this.postsRefCallback(handler));

    return postsQuery.ref
  }

  /**
   * Callback for Firebase References or Query functions.
   * @param {function} handler - Function to further handle the resulting posts.
   * @param {boolean} skipDuplicates - Skip posts whose 'source' property is a duplicate of other posts. The oldest duplicate posts will be skipped.
   * @return {Object[]} List of posts.
   */
  postsRefCallback(handler, skipDuplicates = false) {
    return snapshots => {
      const posts = [];
      const uniqueSources = [];
      snapshots.forEach(snapshot => {
        snapshot.forEach(postSnap => {
          const post = postSnap.val();
          if (skipDuplicates && uniqueSources.includes(post.source)) return;
          uniqueSources.push(post.source);
          const lyd = {...post, lydId: postSnap.key};
          posts.push(lyd);
        })
      })

      const sortedPosts = getSortedPosts(posts);
      if (handler) {
        handler(sortedPosts);
      }
      return sortedPosts;
    }
  }

  async setNewPost(post, completionCallback) {
    const userId = this.auth.currentUser.uid;
    const postsRef = this.db.ref(`/posts/${userId}`);
    const lydId = postsRef.push().key;
    const dateAdded = moment().valueOf();
    const newPost = {
      ...post,
      dateAdded,
      userId,
      lydId,
    };
    console.log("new post!!", newPost)
    const promises = [
      postsRef.child(lydId).update(newPost, completionCallback)
    ];

    if (post.hashtags.length) {
      promises.push(this.setHashtagItems(newPost))
    }
    return Promise.all(promises)
  }

  async deletePost(id) {
    const dbPath = `/posts/${this.auth.currentUser.uid}/${id}`;
    const tags = await this.db.ref(dbPath).once('value')
                        .then(p => p.val().hashtags);
    
    if (tags) {
      return this.deleteHashtags(id, tags)
        .then(() => this.db.ref(dbPath).remove());
    } else {
      return this.db.ref(dbPath).remove();
    }
  }

  deleteHashtags(id, tags) {
    const removeHashtags = tags.map(tag => this.deleteHashtag(id, tag));
    return Promise.all(removeHashtags)
  }

  deleteHashtag(lydId, tag) {
    const dbPath = `/hashtags/${tag}/${lydId}`;
    return this.db.ref(dbPath).remove();
  }

  setHashtagItems(post) {
    const promises = post.hashtags.map(
      tag => {
        const dbPath = `/hashtags/${tag}/${post.lydId}`;
        const tagItem = { 
          userId: post.userId, 
          htId: post.lydId, // Placeholder, in case we start using a unique id for each hashtag.
          lydId: post.lydId
        };
        return this.db.ref(dbPath).update(tagItem);
    });
    
    return Promise.all(promises)
  }

  getHashtagItems(tags) {
    const getTags = tags.map(
      tag => this.db.ref(`hashtags/${tag}`).once('value')
    );
    
    const hashtagItems = Promise.all(getTags).then(snapshots => {
      const hashtags = [];
      snapshots.forEach(tagSnaps => {
        tagSnaps.forEach(tagSnap => {
          const hashtag = tagSnap.val();
          const tagItem = {...hashtag};
          hashtags.push(tagItem); 
        });
      });
      // console.log(hashtags)
      return hashtags;
    });

    return hashtagItems;
  }

  async getNumPosts(uids) {
    const promises = [];
    uids.forEach(uid => {
      const promise = this.db.ref(`posts/${uid}`)
                        .once('value').then(ss => ss.numChildren());
      promises.push(promise);
    })

    const numUserPosts = await Promise.all(promises);
    let numPosts = 0;
    numUserPosts.forEach(num => {
      if (num) numPosts += num;
    })

    return numPosts; 
  }

  async getPosts(uids) {
    const promises = [];
    uids.forEach(uid => {
      const promise = this.db.ref(`posts/${uid}`)
                        .once('value').then(ss => ss.val());
      promises.push(promise);
    })

    const userPosts = await Promise.all(promises);
    let mergedPosts = {};
    userPosts.forEach(posts => {
      if (posts) 
        mergedPosts = { ...mergedPosts, ...posts };
    })

    return mergedPosts; 
  }

  getPost(uid, lydId) {
    const dbPath = `/posts/${uid}/${lydId}`;
    return this.db.ref(dbPath).once('value')
          .then(ss => ss.val());
  }

  async getPostsFromHashtags(tags) {
    const hashtagItems = await this.getHashtagItems(tags);
    const getPosts = hashtagItems.map(hti => 
      this.getPost(hti.userId, hti.lydId)
    );

    const postsCb = posts => {
      const restructuredPosts = {};
      posts.forEach(post => {
        if (post) restructuredPosts[post.lydId] = post;
      })
      return restructuredPosts;
    }
    
    return Promise.all(getPosts).then(postsCb);
  }

  async getPostsAndProfilesFromHashtags(tags, handler) {
    const hashtagItems = await this.getHashtagItems(tags);
    const getPosts = hashtagItems.map(hti => 
      this.getPost(hti.userId, hti.lydId)
    );
    
    const uids = [];
    let posts = await Promise.all(getPosts);
    posts = posts.filter(post => {
      if (post) {
        const uid = post.userId;
        if (!uids.includes(uid)) uids.push(uid);
        return true;
      } else {
        return false;
      }
    })
    const profiles = await this.profilesRefOnce(uids);
    const mergedPosts = posts.map(post => (
      {...post, user: profiles[post.userId]}
    ));
    const sortedPosts = getSortedPosts(mergedPosts);
    if (handler) {
      handler(sortedPosts);
    };
    return sortedPosts;
  }

  // *******************************************************************
  // *                           Social API                            *
  // *******************************************************************
  async getConnectionStatus(uid1, net, uid2) {
    var connStatus;
    if (uid1 === uid2) return {}; // Base case: Should this be FOLLOW_CODE instead?

    // Following? or Follower?
    connStatus = await this.db.ref(`user_network/${uid1}/${net}/${uid2}`)
                        .once('value').then(ss => ss.val())
    
    // Following? or Follower requested?
    if (!connStatus) {
      connStatus = await this.db.ref(`user_network/${uid1}/${net}_pending/${uid2}`)
                          .once('value').then(ss => ss.val())
    }

    // not Following nor Follower
    if (!connStatus) {
      connStatus = { status: SOCIAL.UNFOLLOW_CODE };
    }
    
    return connStatus;
  }

  getSocialNetwork(uid, net) {
    const dbPath = `user_network/${uid}/${net}`;
    return this.db.ref(dbPath).once('value')
            .then(ss => ss.val() || {});
  }

  getSocialNetworkListener(uid, net, callback) {
    const dbPath = `user_network/${uid}/${net}`;
    this.db.ref(dbPath).on('value', callback);
    return this.db.ref(dbPath);
  }

  getSocialNetwork(uid, net) {
    const dbPath = `user_network/${uid}/${net}`;
    return this.db.ref(dbPath).once('value')
            .then(ss => ss.val() || {});
  }

  async getMutualConnection(uid, net, mutualOnly=false) {
    const authUid = this.auth.currentUser && this.auth.currentUser.uid;
    if (!authUid) throw {message: 'must first be authenticated'}
    if (authUid === uid) {
      return await this.getSocialNetwork(authUid, net);
    }
    
    const following = await this.getSocialNetwork(authUid, 'following');
    const theirNet = await this.getSocialNetwork(uid, net);
    const mutualConnections = {};
    Object.keys(theirNet).map(uid => {
      if (following[uid]) {
        mutualConnections[uid] = following[uid];
      }
    });

    if (mutualOnly) {
      return mutualConnections;
    } else {
      const nonConnections = {};
      for (const uid in theirNet) {
        nonConnections[uid] = { status: SOCIAL.UNFOLLOW_CODE };
      }
      return { ...nonConnections, ...mutualConnections };
    }
  }

  async getMutualConnectionProfiles(uid, net, mutualOnly=false) {
    const conns = await this.getMutualConnection(uid, net, mutualOnly);
    const connsUids = Object.keys(conns);
    const mergeProfiles = connsUids.map(uid => 
      this.getUserProfile(uid)
        .then(profile => ({ ...profile, ...conns[uid] }))
    );

    return Promise.all(mergeProfiles);
  }
  
  async getSocialNetConnection(uid, net) {
    const authUid = this.auth.currentUser && this.auth.currentUser.uid;
    if (!authUid) return { status: SOCIAL.UNFOLLOW_CODE }
    const networkRef = this.db.ref(`user_network/${uid}`);
    let socialStatus = await networkRef.child(`${net}/${authUid}`)
                              .once('value')
                              .then(snapshot => snapshot.val());
    if (!socialStatus) {
      socialStatus = await networkRef.child(`${net}_pending/${authUid}`)
                            .once('value').then(snapshot => snapshot.val());
    }
    if (!socialStatus){
      socialStatus = { status: SOCIAL.UNFOLLOW_CODE };
    }
    // console.log(socialStatus);
    return socialStatus;
  }

  async followUserPromise(uid) {
      const authUid = this.auth.currentUser.uid;
      const followersRef = this.db.ref(`user_network/${uid}/followers/${authUid}`);
      const followingRef = this.db.ref(`user_network/${authUid}/following/${uid}`);
      const followersPendingRef = this.db.ref(`user_network/${uid}/followers_pending/${authUid}`);
      const followingPendingRef = this.db.ref(`user_network/${authUid}/following_pending/${uid}`);
  
      const value = {'dateRequested': moment().valueOf(), 'status': SOCIAL.FOLLOW_CODE};
      try {
        await followersRef.set(value);
        await followingRef.set(value);
        return value;

      } catch(err) {
        value['status'] = SOCIAL.FOLLOW_REQUEST_CODE;
        value['dateRequested'] = moment().valueOf();
        await followersPendingRef.set(value);
        await followingPendingRef.set(value);
        return value;
      }
  }

  async unfollowUserPromise(uid) {
    const authUid = this.auth.currentUser.uid;
    const dbPaths = [
      `user_network/${uid}/followers/${authUid}`,
      `user_network/${authUid}/following/${uid}`,
      `user_network/${uid}/followers_pending/${authUid}`,
      `user_network/${authUid}/following_pending/${uid}`,
    ];

    const promises = dbPaths.map(p => this.db.ref(p).remove());
    await Promise.all(promises);
    return  {'dateRequested': moment().valueOf(), status: SOCIAL.UNFOLLOW_CODE};
  }

  performFollowAction(uid, statusCode) {
    if (statusCode === SOCIAL.UNFOLLOW_CODE) {
        return this.followUserPromise(uid);
    } else {
        return this.unfollowUserPromise(uid);
    }
  }

  respondFollowRequest(uid, isAccepted) {
    if (isAccepted) {
      return this.acceptFollowRequest(uid);
    } else {
      return this.rejectFollowRequest(uid);
    }
  }
  
  async respondAllFollowRequests(accept) {
    const authUid = this.auth.currentUser.uid;
    const pendingPath = `user_network/${authUid}/followers_pending`;
    const uids = await this.db.ref(pendingPath).once('value')
                        .then(ss => ss.val()? Object.keys(ss.val()) : []);
    
    const respondFollowRequest =
      accept?
      this.acceptFollowRequest :
      this.rejectFollowRequest;
    
      const promises = uids.map(uid => respondFollowRequest(uid));
    try {
      await Promise.all(promises)
      return true;

    } catch (err) {
      return false;
    }
  }

  async acceptFollowRequest(uid) {
    const authUid = this.auth.currentUser.uid;
    const val = {'dateRequested': moment().valueOf(), 'status': SOCIAL.FOLLOW_CODE};
    const followersPath = `user_network/${authUid}/followers/${uid}`;
    const followingPath = `user_network/${uid}/following/${authUid}`;
    const followersPendingPath = `user_network/${authUid}/followers_pending/${uid}`;
    const followingPendingPath = `user_network/${uid}/following_pending/${authUid}`;
    
    const updatedValues = {};
    updatedValues[followersPath] = val;
    updatedValues[followingPath] = val;
    updatedValues[followersPendingPath] = null;
    updatedValues[followingPendingPath] = null;
    
    console.log("**** accepting request!:", updatedValues);
    await this.db.ref().update(updatedValues);
    return val;
  }
  
  async rejectFollowRequest(uid) {
    const authUid = this.auth.currentUser.uid;
    const val = {'dateRejected': moment().valueOf(), 'status': SOCIAL.UNFOLLOW_CODE}
    const dbPaths = [
      `user_network/${authUid}/followers/${uid}`,
      `user_network/${uid}/following/${authUid}`,
      `user_network/${authUid}/followers_pending/${uid}`,
      `user_network/${uid}/following_pending/${authUid}`,
    ]
    const promises = dbPaths.map(p => this.db.ref(p).remove())
    await Promise.all(promises);
    return val;
  }

  setLastViewedLikes(now) {
    const authUid = this.auth.currentUser && this.auth.currentUser.uid;
    if (!authUid) throw {message: 'must first be authenticated'}
    return this.db.ref(`notifications/${authUid}/likes/lastViewed`)
                    .set(now);
  }

  getLastViewedLikes() {
    const authUid = this.auth.currentUser && this.auth.currentUser.uid;
    if (!authUid) throw {message: 'must first be authenticated'}
    return this.db.ref(`notifications/${authUid}/likes/lastViewed`)
                    .once('value').then(ss => ss.val() || 0);
  }

  async getLikeItem(lydId, uid, dateLiked) {
    const profile = await this.getUserProfiles([uid])
                            .then(profiles => profiles[0]);
    const authUid = this.auth.currentUser && this.auth.currentUser.uid;
    const lyd = await this.getPost(authUid, lydId);
    const item = { 
      ...profile, 
      likedLyd: { ...dateLiked, ...lyd }
    }
    console.log(item)
    return item;
  }
  async getLikeItem(lydId, uid, dateLiked) {
    const profile = await this.getUserProfiles([uid])
                            .then(profiles => profiles[0]);
    const authUid = this.auth.currentUser && this.auth.currentUser.uid;
    const lyd = await this.getPost(authUid, lydId);
    const item = { 
      ...profile, 
      likedLyd: { ...dateLiked, ...lyd }
    }
    console.log(item)
    return item;
  }

  async getLikeItems(dateMin) {
    const MIN = dateMin || 0;
    const authUid = this.auth.currentUser && this.auth.currentUser.uid;
    if (!authUid) throw {message: 'must first be authenticated'}
    const lyds = await this.db.ref(`posts/${authUid}`).once('value')
                          .then(ss => ss.val() || {});
    const postLikes = await this.db.ref(`postLikes/${authUid}`).once('value').then(ss => ss.val());
    if (!postLikes) return [];

    let uids = [];
    Object.values(postLikes || {}).forEach(likes => {
      uids = uids.concat(Object.keys(likes));
    });
    uids = Array.from(new Set(uids));

    const profiles = await this.getUserProfiles(uids);

    const likes = [];
    for (var lydId in postLikes) {
      const likeItems = postLikes[lydId];
      for (var uid in likeItems) {
        const likeItem = likeItems[uid];
        if (likeItem.dateLiked < MIN) continue;
        var item;
        profiles.forEach(profile => {
          if (profile.userId === uid) {
            item = { 
              ...profile, 
              likedLyd: { ...likeItem, ...lyds[lydId] }
            }
            likes.unshift(item);
          }
        })
      }
    }
    return likes;
  }

  isPostLiked(lyd) {
    return this.getPostLiked(lyd).then(val => !!val);
  }

  getPostLiked(lyd) {
    const authUid = this.auth.currentUser && this.auth.currentUser.uid;
    if (!authUid) throw {message: 'must first be authenticated'}
    const uid = lyd.userId;
    const likesRef = this.db.ref(`postLikes/${uid}/${lyd.lydId}/${authUid}`);
    return likesRef.once('value').then(ss => ss.val());
  }

  setLike(lyd, callback) {
    const authUid = this.auth.currentUser.uid;
    const uid = lyd.userId;
    const likesRef = this.db.ref(`postLikes/${uid}/${lyd.lydId}/${authUid}`);
    likesRef.transaction(currentData => {
      if (currentData === null) {
        return { 
          dateLiked: moment().valueOf(), 
          likedBy: authUid, 
          title: lyd.title, 
          lydId: lyd.lydId
        };
      } else {
        return null;
      }
    }, (error, committed, snapshot) => {
      if (error) {
      } else if (!committed) {
      } else {
        if (callback) callback(snapshot);
      }
    });
  }

  // *******************************************************************
  // *                          Notifications API                      *
  // *******************************************************************

  getLastViewedSocial() {
    const authUid = this.auth.currentUser.uid;
    return this.db.ref(`notifications/${authUid}/social/lastViewed`)
      .once('value')
      .then(ss => {
        const lastViewed = ss.val();
        if (lastViewed) {
          return lastViewed;
        } else {
          return 0;
          // return moment.unix(0).format();
        }
      });
  }

  // *******************************************************************
  // *                          Analytics API                          *
  // *******************************************************************

  addNewDevice(uid, compKey) {
    this.db.ref(`analytics/${uid}/devices/${compKey}`);
  }
  _runAnalytics(snapshot, io) {
    this.db.ref(`analytics/${io}/count`)
      .transaction(current => {
        return (current || 0) + 1;
      });

    return snapshot;
  }
}

export default Firebase;