import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const PlayPauseButton = ({ lyd, currentLyd, onSetLyd, onSetPlaying, isPlaying, customStyle, className, children}) => {
  const [playing, setPlaying] = useState(false);
  
  useEffect(() => {
    if (currentLyd && currentLyd.lydId === lyd.lydId){
      setPlaying(isPlaying);
    } else {
      setPlaying(false);
    }
  }, [currentLyd, isPlaying])

  const onTogglePlay = event => {
    event.preventDefault();
    if (!currentLyd || 
        (currentLyd.lydId !== lyd.lydId)) {
      onSetLyd(lyd);
    } 
    onSetPlaying(!playing);
    setPlaying(!playing);
  }

  return (
    <div 
      style={{ ...customStyle }}
      className={className}
      onClick={onTogglePlay}>
      {children}
    </div>
  )
}

const mapStateToProps = state => ({
  currentLyd: state.lydsState.lyd,
  isPlaying: state.lydsState.playing,
});

const mapDispatchToProps = dispatch => ({
  onSetLyd: lyd => dispatch({type: 'LYD_SET', lyd}),
  onSetPlaying: playing => dispatch({type: 'PLAYING_SET', playing}),
});

export default compose(
connect(
  mapStateToProps,
  mapDispatchToProps,
)
)(PlayPauseButton);