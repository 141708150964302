import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import MainPlayer from '../../containers/PlayerContainer';

function PlayerBar(props) {
  // console.log('$$$$$$$$$$  PlayerBar props: ', props)
  if (!props.posts.length) return null;
  
  return (
    <div>
      <MainPlayer stream={props.stream} />
    </div>
  );
}

const mapStateToProps = state => ({
  posts: state.lydsState.posts,
  stream: state.lydsState.stream,
});

export default compose(
  connect(mapStateToProps)
)(PlayerBar);